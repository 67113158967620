import React from "react";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import TextWithTooltip from "./TextWithTooltip";

export default function RosterStatsRow(props) {
  return <Column spacing={0}>
    <Row spacing="medium medium" alignmentHorizontal={"center"}>
      <Row spacing="small" alignmentHorizontal={"center"}>
        <TextWithTooltip tooltipText="#Shift-Status" value={props.status} textClass={props.textClass}/>
      </Row>
    </Row>
    <Row alignmentHorizontal="center">
      <TextWithTooltip tooltipText="Shift start local time" value={props.startTime} textClass="dateTextGrey"/>
      <Text><b>through</b></Text>
      <TextWithTooltip tooltipText="Shift end local time" value={props.endTime} textClass="dateTextGrey"/>
    </Row>
  </Column>
}