import { TableCell, TableRow } from "@amzn/meridian/table";
import {useCallback, useState} from "react";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import React from "react";
import {MappingInfo} from "../../../admin-api/generated-src";
import DeleteRowModal from "../../modal/DeleteRowModal";



function CalmCodeRow({
                         role,
                         handleSaveClick,
                         handleDeleteClick,
                         showRoleType
                     }) {

    const [calmCode, setCalmCode] = useState(role.calmCode);
    const [delayInMinutes, setdelayInMinutes] = useState(role.delayInMinutes);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);


    /**
     * Function to handle save button
     */
    const saveHandler = () => {
            const updatedRole: MappingInfo = {
                nodeId: role.nodeId,
                sargRoleUniqueId: role.sargRoleUniqueId,
                roleName: role.roleName,
                roleType: role.roleType,
                calmCode: calmCode.trim(),
                delayInMinutes: delayInMinutes,
            };
        handleSaveClick(updatedRole);
    };

    const getUniqueKey = (sargRoleId, fieldName) => {
        return `${sargRoleId}-${fieldName}`;
    };

    const deleteHandler = () => {
        setOpenDeleteModal(true)
    }
    const onConfirmDelete = useCallback(() => setOpenDeleteModal(false), []);

    return (
        <>
            <DeleteRowModal
                open={openDeleteModal}
                onClose={onConfirmDelete}
                confirmHandler={handleDeleteClick}
                role={role}
            />
            <TableRow key={getUniqueKey(role.sargRoleUniqueId, "row")}>
                    <TableCell
                        key={getUniqueKey(role.sargRoleUniqueId, "roleName")}>
                        {role.roleName}
                    </TableCell>
                    <TableCell
                        key={getUniqueKey(role.sargRoleUniqueId, "roleType")}
                        showCell={showRoleType}
                    >
                        {role.roleType}
                    </TableCell>
                    <TableCell key={getUniqueKey(role.sargRoleUniqueId, "calmCode")}>
                        <Input
                            type="text"
                            value={calmCode}
                            onChange={setCalmCode}
                            data-type={role.roleName + "calmCode"}
                        />
                    </TableCell>
                    <TableCell
                        key={getUniqueKey(role.sargRoleUniqueId, "delayInMinutes")}
                    >
                        <Input
                            type="number"
                            value={delayInMinutes}
                            onChange={setdelayInMinutes}
                            data-type={role.roleName + "delayInMinutes"}
                        />
                    </TableCell>
                    <TableCell >
                        <Button onClick={saveHandler} data-type={role.roleName + "save"}>Update</Button>
                    </TableCell>
                    <TableCell  >
                        <Button onClick={deleteHandler} data-type={role.roleName + "delete"}>Delete</Button>
                    </TableCell>
            </TableRow>
        </>
    );
}

export default CalmCodeRow;