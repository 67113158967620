import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Column from "@amzn/meridian/column";
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import { TableSortDirection } from "@amzn/meridian/table/table";
import Input from "@amzn/meridian/input";
import Pagination from "@amzn/meridian/pagination"
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select"
import Text from "@amzn/meridian/text";
import GreenCheckMarkWithTooltip from "./GreenCheckMarkWithTooltip";
import RedCrossWithTooltip from "./RedCrossWithTooltip";
import AssociateAliasCell from "./AssociateAliasCell";
import TrainedRolesCell from "./TrainedRolesCell";
import {
  AUDIT_TABLE_OBJECT_LIST,
  ASSOCIATE_ALIAS_FIELDNAME,
  DEFAULT_ITEMS_PER_PAGE,
  TRAINED_ROLES_FIELDNAME,
  BADGE_IN_STATUS_FIELDNAME,
  LABOR_TRACKED_FIELDNAME,
  TIMESTAMP_FIELDNAME,
  DROPDOWN_TYPE,
} from "../../constants";
import "./AuditComponents.scss";
import orderBy from "lodash/orderBy";
import SearchableSelect from "./SearchableSelect";
import uniq from "lodash/uniq";

const BADGE_STATUS_TOKEN_MAP = {
  "Scanned": <><GreenCheckMarkWithTooltip />{" "}Scanned</>,
  "Not Scanned": <><RedCrossWithTooltip />{" "}Not Scanned</>,
}

export default function AuditDetailsTable({ auditRecordList }) {
  const { nodeId } = useParams<{ nodeId: string }>();
  const [userAuditRecordList, setUserAuditRecordList] = useState(auditRecordList);
  const [userFilters, setUserFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const [sortColumn, setSortColumn] = useState(TIMESTAMP_FIELDNAME);
  const [sortDirection, setSortDirection] = useState<TableSortDirection>("ascending");
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
  const numberOfPages = Math.ceil(auditRecordList.length / itemsPerPage);

  useEffect(() => {
    setUserAuditRecordList(auditRecordList);
  }, [auditRecordList]);

  const formatTimestampCell = (timestampStr) => {
    if (!timestampStr || !timestampStr.includes("T") || !timestampStr.includes("Z")) {
      return "";
    }
    return timestampStr.split("T")[1].split("Z")[0].split(".")[0]
  }

  const getTableCellValueFromFieldName = (auditRecord, fieldName) => {
    switch (fieldName) {
      case TIMESTAMP_FIELDNAME:
        return formatTimestampCell(auditRecord[fieldName]);
      case ASSOCIATE_ALIAS_FIELDNAME:
        const ppaUrl = `https://fclm-portal.amazon.com/employee/ppaTimeDetails?employeeId=${auditRecord[fieldName]}&warehouseId=${nodeId}`
        return <AssociateAliasCell ppaUrl={ppaUrl} associateAlias={auditRecord[fieldName]} />;
      case TRAINED_ROLES_FIELDNAME:
        const trainedRolesStr = auditRecord[fieldName] && auditRecord[fieldName].join(",") || "";
        return <TrainedRolesCell trainedRolesStr={trainedRolesStr} />
      case BADGE_IN_STATUS_FIELDNAME:
        return BADGE_STATUS_TOKEN_MAP[auditRecord[fieldName]];
      case LABOR_TRACKED_FIELDNAME:
        return auditRecord[fieldName] ? "Yes" : "No";
      default:
        return auditRecord[fieldName];
    }
  }

  const changeUserFiltersHandler = (fieldName) => (inputValue) => {
    let updatedValue = { [fieldName]: inputValue };
    setUserFilters((prevUserFilters) => ({
      ...prevUserFilters,
      ...updatedValue
    }));
    setCurrentPage(1);
  }

  const filterMethod = (userAuditRecord) => {
    // inside the for loop should be conditions only return false, otherwise the loop will break resulting not all userFilters checked
    for (let key in userFilters) {
      if (userFilters[key]) {
        if (userAuditRecord[key] === undefined || userAuditRecord[key] === null) {
          return false;
        } else if (Array.isArray(userAuditRecord[key])) {  // trainedRoles
          const found = userAuditRecord[key].find(role => role.toString().toLowerCase().includes(userFilters[key].toString().toLowerCase()));
          if (found === undefined) {
            return false;
          }
        } else if (typeof userAuditRecord[key] === 'boolean') {  // laborTracked
          if (userFilters[key].toLowerCase() === "yes" && !userAuditRecord[key] ||
            userFilters[key].toLowerCase() === "no" && userAuditRecord[key]) {
            return false;
          }
        } else if (key === BADGE_IN_STATUS_FIELDNAME && userAuditRecord[key]) {
          if (!userAuditRecord[key].toLowerCase().startsWith(userFilters[key].toLowerCase())) {
            return false;
          }
        } else if (!userAuditRecord[key].toLowerCase().includes(userFilters[key].toLowerCase())) {
          return false;
        }
      }
    }
    return true;
  }

  const sortAuditRecordsHandler = (event) => {
    setUserAuditRecordList(
      orderBy(
        userAuditRecordList,
        [sortColumn],
        [sortDirection === "ascending" ? "asc" : "desc"]
      )
    );
    setSortColumn(event.sortColumn);
    setSortDirection(event.sortDirection);
  }

  const getDropdownOptions = (fieldName, userAuditRecordList) => {
    if (fieldName === TRAINED_ROLES_FIELDNAME) {
      let fieldValueOptions = userAuditRecordList.filter(record => record[fieldName] !== null).map((record) => record[fieldName]);
      let trainedRolesOptions = fieldValueOptions.join(",").split(",");
      return uniq(trainedRolesOptions);
    }
    else if (fieldName === LABOR_TRACKED_FIELDNAME) {
      let fieldValueOptions = userAuditRecordList.map((record) => record[fieldName] ? "Yes" : "No");
      return uniq(fieldValueOptions);
    }
    else {
      let fieldValueOptions = userAuditRecordList.map((record) => record[fieldName]);
      return uniq(fieldValueOptions);
    }
  }

  const getInputFilter = (title, fieldName, fieldType) => {
    if (userAuditRecordList.length) {
      if (fieldType === DROPDOWN_TYPE) {
        return <SearchableSelect
          userSelectValue={userFilters[fieldName]}
          changeValueHandler={changeUserFiltersHandler}
          fieldName={fieldName}
          title={title}
          options={getDropdownOptions(fieldName, userAuditRecordList)}
        />;
      }
      else {
        return <Input
          key={`${fieldName}-filter`}
          placeholder={title}
          value={userFilters[fieldName]}
          onChange={changeUserFiltersHandler(fieldName)}
        />;
      }
    }
  }

  return <Column>
    <Table
      headerRows={1}
      stickyHeaderRow
      showDividers
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      onSort={sortAuditRecordsHandler}
      layout="fixed"
    >
      <TableRow>
        {AUDIT_TABLE_OBJECT_LIST.map(({ title, fieldName }) => <TableCell key={title} sortColumn={fieldName}>{title}</TableCell>)}
      </TableRow>
      <TableRow>
        {AUDIT_TABLE_OBJECT_LIST.map(({ title, fieldName, fieldType }) => <TableCell key={`${title}-input`}>
          {getInputFilter(title, fieldName, fieldType)}
        </TableCell>)}
      </TableRow>
      {userAuditRecordList
        .filter(filterMethod)
        .slice(firstVisibleIndex, lastVisibleIndex)
        .map((auditRecord) => {
          const { assignedRole,
            associateLaborTrackType,
            associateAlias,
            timestamp,
          } = auditRecord;
          return <TableRow key={`${associateAlias}-${associateLaborTrackType}-${assignedRole}-${timestamp}-row`}>
            {AUDIT_TABLE_OBJECT_LIST.map(({ fieldName }) => <TableCell
              key={`${associateAlias}-${associateLaborTrackType}-${timestamp}-${fieldName}-cell`}
            >
              {getTableCellValueFromFieldName(auditRecord, fieldName)}
            </TableCell>
            )}
          </TableRow>
        })}
    </Table>
    <Row alignmentHorizontal="justify">
      <Row>
        <Text>{`Showing ${firstVisibleIndex} to ${lastVisibleIndex} of ${auditRecordList.length} entries`}</Text>
        <Select
          value={itemsPerPage}
          onChange={setItemsPerPage}
          size="small"
        >
          <SelectOption value={10} label="10" />
          <SelectOption value={20} label="20" />
          <SelectOption value={30} label="30" />
          <SelectOption value={40} label="40" />
        </Select>
        <Text>per page</Text>
      </Row>
      <Pagination
        showSkipArrows={true}
        numberOfPages={numberOfPages}
        onChange={setCurrentPage}
        currentPage={currentPage}
      />
    </Row>
  </Column>
}