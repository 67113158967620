import React from "react";
import Modal, {ModalFooter} from "@amzn/meridian/modal";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";

export default function DeleteRowModal({ open, onClose, confirmHandler, role }) {
    const shortText = "Are you sure you want to delete it?"
    return (
        <>
            <Modal
                open={open}
                title=""
                onClose={onClose}
                scrollContainer="viewport"
                width={300}
                closeLabel="Close"
            >
                <Text id="modal-description">{shortText}</Text>
                <ModalFooter>
                    <Row alignmentHorizontal="right" widths="fit">
                        <Button
                            type="secondary"
                            size="small"
                            onClick={onClose}
                            minWidth={60}
                            id="modalDeleteNo"
                        >
                            NO
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => confirmHandler(role)}
                            minWidth={60}
                            id="modalDeleteYes"
                        >
                            YES
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    );
}
