import React from "react";
import {Trans} from "react-i18next";
import i18n from "../../../i18n";
import "./Success.scss";

export default function Success(props) {
    const { employeeLogin, roleName } = (props.badgeScanAssignmentInfo.rosterEmployeeAssignmentDetails) || {};
    const { shiftType } = (props.rosterShiftInfo) || {};

    return (
        <section>
            <p className="welcomeText" data-testid="welcomeText">
                <Trans>{i18n.t('success_message',{employeeLogin, shiftType})}</Trans>
            </p>
            <p className="roleName" data-testid="roleName">
                {roleName}
            </p>
        </section>
    );
}