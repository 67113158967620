import React from "react";
import Icon from "@amzn/meridian/icon";
import Tooltip from "@amzn/meridian/tooltip";
import bulbTokens from "@amzn/meridian-tokens/base/icon/bulb";
import { colorYellow400 } from "@amzn/meridian-tokens/base/color";
import { css } from "emotion";

const yellowText = css({
  color: colorYellow400
});

export default function YellowBulbWithTooltip({ tooltipText = "#Auto Labor Tracked" }) {
  return <span className={yellowText} >
    <Tooltip position="top" title={tooltipText}>
      <Icon tokens={bulbTokens} />
    </Tooltip>
  </span>
}