import React, { createContext, useState } from "react";
import { LMSLaborTrackingRole, EmployeeTrainedLMSCertificates } from "../admin-api/generated-src";

interface lmsmanualLaborTrackStateProps {
  employeeId: string;
  employeeTrainedCertificates: EmployeeTrainedLMSCertificates;
  laborTrackCertificateList: LMSLaborTrackingRole[];
  selectedCertificateName: string;
  showModal: boolean;
  showRadioButton: boolean;
  setEmployeeId(employeeId: string): any;
  setEmployeeTrainedLMSCertificates(employeeTrainedCertificates: EmployeeTrainedLMSCertificates): any;
  setLaborTrackCertificateList(laborTrackCertificates: LMSLaborTrackingRole[]): any;
  setSelectedCertificateName(roleName: string): any;
  setShowModal(showModal: boolean): any;
  setShowRadioButton(showRadioButton: boolean): any;
}

export const initialState: lmsmanualLaborTrackStateProps = {
  employeeId: "",
  employeeTrainedCertificates: {},
  laborTrackCertificateList: [],
  selectedCertificateName: "",
  showModal: false,
  showRadioButton: false,
  setEmployeeId: () => { },
  setEmployeeTrainedLMSCertificates: () => { },
  setLaborTrackCertificateList: () => { },
  setSelectedCertificateName: () => { },
  setShowModal: () => { },
  setShowRadioButton: () => { },
};

const LMSManualLaborTrackContext = createContext(initialState);

export function LMSManualLaborTrackContextProvider({ children }) {
  const [userEmployeeId, setUserEmployeeId] = useState(
    initialState.employeeId
  );
  const [userEmployeeTrainedLMSCertificates, setUserEmployeeTrainedLMSCertificates] = useState(initialState.employeeTrainedCertificates);
  const [userLaborTrackCertificateList, setUserLaborTrackCertificateList] = useState(initialState.laborTrackCertificateList);
  const [userSelectedCertificateName, setUserSelectedCertificateName] = useState(initialState.selectedCertificateName);
  const [userShowModal, setUserShowModal] = useState(false);
  const [userShowRadioButton, setUserShowRadioButton] = useState(false);

  function setEmployeeId(employeeId) {
    if (employeeId !== userEmployeeId) {
      setUserEmployeeId(employeeId);
    }
  }

  function setEmployeeTrainedLMSCertificates(employeeTrainedCertificates) {
    setUserEmployeeTrainedLMSCertificates(employeeTrainedCertificates);
  }

  function setLaborTrackCertificateList(laborTrackCertificateList) {
    setUserLaborTrackCertificateList(laborTrackCertificateList);
  }

  function setSelectedCertificateName(roleName) {
    setUserSelectedCertificateName(roleName);
  }

  function setShowModal(showModal) {
    setUserShowModal(showModal);
  }

  function setShowRadioButton(showRadioButton) {
    setUserShowRadioButton(showRadioButton);
  }

  const context: lmsmanualLaborTrackStateProps = {
    employeeId: userEmployeeId,
    employeeTrainedCertificates: userEmployeeTrainedLMSCertificates,
    laborTrackCertificateList: userLaborTrackCertificateList,
    selectedCertificateName: userSelectedCertificateName,
    showModal: userShowModal,
    showRadioButton: userShowRadioButton,
    setEmployeeId,
    setEmployeeTrainedLMSCertificates,
    setLaborTrackCertificateList,
    setSelectedCertificateName,
    setShowModal,
    setShowRadioButton,
  };

  return (
    <LMSManualLaborTrackContext.Provider value={context}>{children}</LMSManualLaborTrackContext.Provider>
  );
}

export default LMSManualLaborTrackContext;
