import React from "react";
import Button from "@amzn/meridian/button";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

export default function AddRolesModal({ open, onClose, confirmHandler }) {
  const shortText = "You are about to add a new role, are you sure?";
  return (
    <React.Fragment>
      <Modal
        open={open}
        title=""
        onClose={onClose}
        scrollContainer="viewport"
        width={300}
        closeLabel="Close"
      >
        <Text id="modal-description">{shortText}</Text>
        <ModalFooter>
          <Row alignmentHorizontal="right" widths="fit">
            <Button
              type="secondary"
              size="small"
              onClick={onClose}
              minWidth={60}
              id="modalAddRoleNo"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={confirmHandler}
              minWidth={60}
              id="modalAddRoleYes"
            >
              OK
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
