import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./KioskLaborTrackPage.scss"
import Title from "./Title";
import Banner from "./Banner";
import ScanForm from "./ScanForm";
import Center from "./Center";
import {useParams} from "react-router";

export default function KioskLaborTrackPage() {
    let { nodeId } = useParams<{ nodeId: string }>();
    return (
        <Center>
            <Title nodeId={nodeId} />
            <Banner>
                <ScanForm nodeId={nodeId} />
            </Banner>
        </Center>
    );
}
