import React from "react";
import {Trans} from "react-i18next";
import i18n from "../../../i18n";
import "./Error.scss";

export default function Error(props) {
    return (
        <section>
            <p className="errorMessage" data-testid="errorMessage">
                <Trans>
                    {(function() {
                            switch(props.status) {
                                case 401:
                                    return i18n.t('error_401');
                                case 402:
                                    return i18n.t('error_402');
                                case 403:
                                    return i18n.t('error_403');
                                default:
                                    return i18n.t('default_error');
                            }
                        }
                    )()}
                </Trans>
            </p>
            <p className="callToAction" data-testid="callToAction">
                {(function() {
                        switch(props.status) {
                            case 401:
                                return i18n.t('special_action');
                            case 402:
                                return i18n.t('special_action');
                            case 403:
                                return i18n.t('special_action');
                            default:
                                return i18n.t('default_action');
                        }
                    }
                )()}
            </p>
        </section>
    );
}