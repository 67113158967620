import React from "react";
import Icon from "@amzn/meridian/icon";
import Tooltip from "@amzn/meridian/tooltip";
import closeMediumTokens from "@amzn/meridian-tokens/base/icon/close-medium";
import { colorRed400 } from "@amzn/meridian-tokens/base/color";
import { css } from "emotion";

const redText = css({
  color: colorRed400
});

export default function RedCrossWithTooltip() {
  return <span className={redText} >
    <Tooltip position="top" title="#Not Badged In">
      <Icon tokens={closeMediumTokens} />
    </Tooltip>
  </span>
}