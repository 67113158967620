import React from "react";
import Link from "@amzn/meridian/link";
import Row from "@amzn/meridian/row";
import { EMPLOYEE_BADGE_URL } from "../../constants";

export default function AssociateAliasCell({ ppaUrl, associateAlias }) {
  return <Row >
    <img alt={`${associateAlias}-img`} src={`${EMPLOYEE_BADGE_URL}${associateAlias}`} height="50vw" />
    <Link href={ppaUrl} type="secondary" target="_blank" rel="noopener noreferrer">{associateAlias}</Link>
  </Row>
}