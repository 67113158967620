import React, { createContext, useState } from "react";
import { LMSLaborTrackingRole } from "../admin-api/generated-src";

interface LmsTableStateProps {
    lmsCertificateList: LMSLaborTrackingRole[];
    reloadLmsList: boolean;
    isLmsTableLoading: boolean;
    setLmsCertificateList(laborTrackCertificates: LMSLaborTrackingRole[]): any;
    setReloadLmsList(reloadLmsList: boolean): any;
    setIsLmsTableLoading(isLmsTableLoading: boolean): any;
}

export const initialState: LmsTableStateProps = {
    lmsCertificateList: [],
    reloadLmsList: false,
    isLmsTableLoading: false,
    setLmsCertificateList: () => { },
    setReloadLmsList: () => { },
    setIsLmsTableLoading: () => { }
};

const LMSTableContext = createContext(initialState);

export function LMSTableContextProvider({ children }) {
    const [userLmsCertificateList, setUserLmsCertificateList] = useState(
        initialState.lmsCertificateList
    );
    const [userIsLmsTableLoading, setUserIsLmsTableLoading] = useState(initialState.isLmsTableLoading);
    const [userReloadLmsList, setUserReloadLmsList] = useState(false);

    function setLmsCertificateList(laborTrackCertificateList) {
        setUserLmsCertificateList(laborTrackCertificateList);
    }

    function setIsLmsTableLoading(isLmsTableLoading) {
        setUserIsLmsTableLoading(isLmsTableLoading);
    }

    function setReloadLmsList(reloadLmsList) {
        setUserReloadLmsList(reloadLmsList);
    }

    const context: LmsTableStateProps = {
        lmsCertificateList: userLmsCertificateList,
        isLmsTableLoading: userIsLmsTableLoading,
        reloadLmsList: userReloadLmsList,
        setLmsCertificateList,
        setIsLmsTableLoading,
        setReloadLmsList,
    };

    return (
        <LMSTableContext.Provider value={context}>{children}</LMSTableContext.Provider>
    );
}

export default LMSTableContext;
