import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import { ALERT_DEFAULT_MESSAGE, ALERT_AUTHENTICATION_MESSAGE } from "../../constants";
import ContentLayout from "../layout/ContentLayout";
import AlertErrorWithMessage from "../alert/AlertErrorWithMessage";
import AuditStatsRow from "./AuditStatsRow";
import RosterSelect from "./RosterSelect";
import AuditActionGroup from "./AuditActionGroup";
import AuditDetailsTable from "./AuditDetailsTable";
import AdminApiFactory from "../../admin-api/AdminApiFactory";
import AdminContext from "../../store/admin-context";
import AuditContext from "../../store/audit-store";
import { AuditDetails } from "../../admin-api/generated-src";

export default function AuditPage() {
  const adminContext = useContext(AdminContext);
  const auditContext = useContext(AuditContext);
  const { selectedNode, changeSelectedNode } = adminContext;
  const { isAuditTableLoading,
    auditRecordList,
    currentRosterId,
    flipRefresh,
    setCurrentRosterId,
    setRosterIdList,
    setAuditRecordList,
    setAuditStats } = auditContext;
  const { nodeId } = useParams<{ nodeId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(ALERT_DEFAULT_MESSAGE);
  const [hasPermission, setHasPermission] = useState(true);

  const AdminApi = AdminApiFactory();

  useEffect(() => {
    if (nodeId !== selectedNode) {
      changeSelectedNode(nodeId);
    }
  }, [nodeId]);

  const cleanup = () => {
    setIsLoading(false);
    setHasPermission(true);
    setAlertMessage(ALERT_DEFAULT_MESSAGE);
    setShowAlert(false);
    setRosterIdList([]);
    setAuditRecordList([]);
    setAuditStats({});
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const auditDetailsResponse: AuditDetails = (await AdminApi.getAuditDetailsForCurrentShift(nodeId, currentRosterId)).data as AuditDetails;
        if (auditDetailsResponse) {
          const { currRosterId = "",
            NodeRosterIdMappingInfo = [],
            AuditRecord = [],
            AuditStats = {} } = auditDetailsResponse;
          if (currentRosterId === undefined) {
            setCurrentRosterId(formatCurrentRosterId(currRosterId));
          }
          setRosterIdList(NodeRosterIdMappingInfo);
          setAuditRecordList(AuditRecord);
          setAuditStats(AuditStats);
        } else {
          console.error(`Got empty response from /audit/${selectedNode}`)
        }
      } catch (ex: any) {
        setShowAlert(true);
        console.error(`Error fetching /audit/${selectedNode}`, ex);
        if (ex.response && ex.response.status === 403) {
          setHasPermission(false);
          setAlertMessage(ALERT_AUTHENTICATION_MESSAGE);
        }
      } finally {
        setIsLoading(false);
      }
    })();

    return cleanup;
  }, [flipRefresh]);

  function formatCurrentRosterId(currentRosterId) {
    // example: "BFI5:2022-05-09 / MOR [v1]"
    const currentRosterIdSplitStr = currentRosterId.split(":");
    if (currentRosterIdSplitStr.length === 2) {
      return currentRosterIdSplitStr[1];
    } else {
      return "";
    }
  }

  return <ContentLayout nodeId={nodeId} helperText="">
    {showAlert && <AlertErrorWithMessage message={alertMessage} />}
    {isLoading ? <Loader /> : hasPermission && <Column width="100%">
      <Row alignmentHorizontal="justify">
        <RosterSelect />
        <AuditActionGroup />
      </Row>
      {isAuditTableLoading ? <Loader /> : <Column>
        <AuditStatsRow />
        <AuditDetailsTable auditRecordList={auditRecordList} />
      </Column>}
    </Column>}
  </ContentLayout>
}