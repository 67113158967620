import React from "react";
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import Link from "@amzn/meridian/link";
import { ATSQUAD_WIKI_LINK } from "../../constants";
import logo from "../../assets/amazon_atsquad_S.png";

export default function Footer() {
  const FOOTER_ITEM_WIDTH = "60";
  const FOOTER_ITEM_HEIGHT = "18";

  return (
    <Row spacing="large" backgroundColor="secondary" width="100%" height={67}>
      <Box width="1"></Box>
      <Box width={FOOTER_ITEM_WIDTH} height={FOOTER_ITEM_HEIGHT}>
        <img
          src={logo}
          alt="logo"
          width={FOOTER_ITEM_WIDTH}
          height={FOOTER_ITEM_HEIGHT}
        ></img>
      </Box>
      <Box width={FOOTER_ITEM_WIDTH} height={FOOTER_ITEM_HEIGHT}>
        <Link
          href={ATSQUAD_WIKI_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          Wiki
        </Link>
      </Box>
    </Row>
  );
}
