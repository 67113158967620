/* tslint:disable */
/* eslint-disable */
/**
 * FastStart
 * FastStart server created by ATS Squad 
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: atssquad@amazon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdminDetailsWithNodeInfo
 */
export interface AdminDetailsWithNodeInfo {
    /**
     * 
     * @type {string}
     * @memberof AdminDetailsWithNodeInfo
     */
    'login'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDetailsWithNodeInfo
     */
    'nodes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AuditDetails
 */
export interface AuditDetails {
    /**
     * 
     * @type {string}
     * @memberof AuditDetails
     */
    'currRosterId'?: string;
    /**
     * 
     * @type {AuditStats}
     * @memberof AuditDetails
     */
    'AuditStats'?: AuditStats;
    /**
     * 
     * @type {Array<AuditRecord>}
     * @memberof AuditDetails
     */
    'AuditRecord'?: Array<AuditRecord>;
    /**
     * 
     * @type {Array<NodeRosterIdMappingInfo>}
     * @memberof AuditDetails
     */
    'NodeRosterIdMappingInfo'?: Array<NodeRosterIdMappingInfo>;
}
/**
 * 
 * @export
 * @interface AuditRecord
 */
export interface AuditRecord {
    /**
     * 
     * @type {string}
     * @memberof AuditRecord
     */
    'rosterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditRecord
     */
    'shiftType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditRecord
     */
    'shiftPublishingTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditRecord
     */
    'associateAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditRecord
     */
    'associateLaborTrackType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditRecord
     */
    'assignedRole'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuditRecord
     */
    'trainedRoles'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuditRecord
     */
    'trainedCertificates'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AuditRecord
     */
    'badgeScanStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditRecord
     */
    'operatorAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditRecord
     */
    'calmCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuditRecord
     */
    'laborTracked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuditRecord
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface AuditStats
 */
export interface AuditStats {
    /**
     * 
     * @type {string}
     * @memberof AuditStats
     */
    'rosterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditStats
     */
    'shiftType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditStats
     */
    'shiftPublishingTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuditStats
     */
    'kioskScannedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditStats
     */
    'kioskUnScannedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditStats
     */
    'kioskLaborTrackCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditStats
     */
    'kioskNonLaborTrackCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditStats
     */
    'midShiftScannedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditStats
     */
    'midShiftLaborTrackCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditStats
     */
    'midShiftNonLaborTrackCount'?: number;
}
/**
 * 
 * @export
 * @interface EmployeeTrainedLMSCertificate
 */
export interface EmployeeTrainedLMSCertificate {
    /**
     * 
     * @type {string}
     * @memberof EmployeeTrainedLMSCertificate
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTrainedLMSCertificate
     */
    'alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTrainedLMSCertificate
     */
    'badgeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTrainedLMSCertificate
     */
    'employeeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTrainedLMSCertificate
     */
    'scanTimestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTrainedLMSCertificate
     */
    'scanTimestampEpoch'?: string;
    /**
     * 
     * @type {LMSLaborTrackingRole}
     * @memberof EmployeeTrainedLMSCertificate
     */
    'trainedCertificate'?: LMSLaborTrackingRole;
}
/**
 * 
 * @export
 * @interface EmployeeTrainedLMSCertificates
 */
export interface EmployeeTrainedLMSCertificates {
    /**
     * 
     * @type {string}
     * @memberof EmployeeTrainedLMSCertificates
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTrainedLMSCertificates
     */
    'alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTrainedLMSCertificates
     */
    'badgeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTrainedLMSCertificates
     */
    'employeeId'?: string;
    /**
     * 
     * @type {Array<LMSLaborTrackingRole>}
     * @memberof EmployeeTrainedLMSCertificates
     */
    'trainedCertificates'?: Array<LMSLaborTrackingRole>;
}
/**
 * 
 * @export
 * @interface LMSLaborTrackingRole
 */
export interface LMSLaborTrackingRole {
    /**
     * 
     * @type {string}
     * @memberof LMSLaborTrackingRole
     */
    'nodeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LMSLaborTrackingRole
     */
    'certificateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LMSLaborTrackingRole
     */
    'certificateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LMSLaborTrackingRole
     */
    'calmCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LMSLaborTrackingRole
     */
    'isLMS'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LMSLaborTrackingRole
     */
    'delayInMinutes'?: number;
}
/**
 * 
 * @export
 * @interface MappingInfo
 */
export interface MappingInfo {
    /**
     * 
     * @type {string}
     * @memberof MappingInfo
     */
    'nodeId'?: string;
    /**
     * 
     * @type {number}
     * @memberof MappingInfo
     */
    'sargRoleUniqueId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MappingInfo
     */
    'roleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingInfo
     */
    'roleType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingInfo
     */
    'calmCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof MappingInfo
     */
    'delayInMinutes'?: number;
}
/**
 * 
 * @export
 * @interface NodeRosterIdMappingInfo
 */
export interface NodeRosterIdMappingInfo {
    /**
     * 
     * @type {string}
     * @memberof NodeRosterIdMappingInfo
     */
    'nodeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeRosterIdMappingInfo
     */
    'rosterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeRosterIdMappingInfo
     */
    'rosterStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeRosterIdMappingInfo
     */
    'rosterPublishedTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeRosterIdMappingInfo
     */
    'rosterStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeRosterIdMappingInfo
     */
    'rosterEndTime'?: string;
}
/**
 * 
 * @export
 * @interface RosterAssignmentInfo
 */
export interface RosterAssignmentInfo {
    /**
     * 
     * @type {string}
     * @memberof RosterAssignmentInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RosterAssignmentInfo
     */
    'alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof RosterAssignmentInfo
     */
    'badgeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RosterAssignmentInfo
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof RosterAssignmentInfo
     */
    'shift'?: string;
}
/**
 * 
 * @export
 * @interface RosterAssignmentInfoRequest
 */
export interface RosterAssignmentInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof RosterAssignmentInfoRequest
     */
    'nodeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RosterAssignmentInfoRequest
     */
    'badgeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RosterAssignmentInfoRequest
     */
    'scanTimestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof RosterAssignmentInfoRequest
     */
    'scanTimestampEpoch'?: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new LMS Custom Role
         * @param {string} nodeId id of sort center
         * @param {LMSLaborTrackingRole} lMSLaborTrackingRole Custom role to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLMSCustomRoleForSite: async (nodeId: string, lMSLaborTrackingRole: LMSLaborTrackingRole, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('createLMSCustomRoleForSite', 'nodeId', nodeId)
            // verify required parameter 'lMSLaborTrackingRole' is not null or undefined
            assertParamExists('createLMSCustomRoleForSite', 'lMSLaborTrackingRole', lMSLaborTrackingRole)
            const localVarPath = `/admin/lmsCertificate/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lMSLaborTrackingRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete SARG role to Calm Code mapping for Node
         * @param {string} nodeId id of sort center
         * @param {Array<MappingInfo>} mappingInfo sarg role to calm code mapping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalmCodeMapping: async (nodeId: string, mappingInfo: Array<MappingInfo>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('deleteCalmCodeMapping', 'nodeId', nodeId)
            // verify required parameter 'mappingInfo' is not null or undefined
            assertParamExists('deleteCalmCodeMapping', 'mappingInfo', mappingInfo)
            const localVarPath = `/admin/sargRole/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mappingInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete LMSCertificate for Node
         * @param {string} nodeId id of sort center
         * @param {Array<LMSLaborTrackingRole>} lMSLaborTrackingRole LMS role calm code mapping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLmsCertificatesAndCustomRolesForSite: async (nodeId: string, lMSLaborTrackingRole: Array<LMSLaborTrackingRole>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('deleteLmsCertificatesAndCustomRolesForSite', 'nodeId', nodeId)
            // verify required parameter 'lMSLaborTrackingRole' is not null or undefined
            assertParamExists('deleteLmsCertificatesAndCustomRolesForSite', 'lMSLaborTrackingRole', lMSLaborTrackingRole)
            const localVarPath = `/admin/lmsCertificate/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lMSLaborTrackingRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Admin username through midway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminInfoWithNodeDetails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get audit details for current shift
         * @param {string} nodeId id of sort center
         * @param {string} [rosterId] id of roster file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditDetailsForCurrentShift: async (nodeId: string, rosterId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getAuditDetailsForCurrentShift', 'nodeId', nodeId)
            const localVarPath = `/admin/audit/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rosterId !== undefined) {
                localVarQueryParameter['rosterId'] = rosterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all possible LMS and Manual Trained certificates for Employee
         * @param {string} nodeId id of sort center
         * @param {string} employeeId Identifier for employee. Can be badgeId or alias.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLMSCertificatesForEmployee: async (nodeId: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getLMSCertificatesForEmployee', 'nodeId', nodeId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getLMSCertificatesForEmployee', 'employeeId', employeeId)
            const localVarPath = `/admin/lmslaborTrack/{nodeId}/{employeeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get LMSCertificate and Custom roles for Node
         * @param {string} nodeId id of sort center
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLmsCertificatesAndCustomRolesForSite: async (nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getLmsCertificatesAndCustomRolesForSite', 'nodeId', nodeId)
            const localVarPath = `/admin/lmsCertificate/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SARG role to Calm Code mapping for Node
         * @param {string} nodeId id of sort center
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSargRoleCalmCodeMapping: async (nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getSargRoleCalmCodeMapping', 'nodeId', nodeId)
            const localVarPath = `/admin/sargRole/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary start manual labor tracking
         * @param {string} nodeId id of sort center
         * @param {string} employeeId Identifier for employee. Can be badgeId or alias.
         * @param {EmployeeTrainedLMSCertificate} employeeTrainedLMSCertificate Request for starting Manual Labor Tracking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startLMSManualLaborTrackingForEmployee: async (nodeId: string, employeeId: string, employeeTrainedLMSCertificate: EmployeeTrainedLMSCertificate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('startLMSManualLaborTrackingForEmployee', 'nodeId', nodeId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('startLMSManualLaborTrackingForEmployee', 'employeeId', employeeId)
            // verify required parameter 'employeeTrainedLMSCertificate' is not null or undefined
            assertParamExists('startLMSManualLaborTrackingForEmployee', 'employeeTrainedLMSCertificate', employeeTrainedLMSCertificate)
            const localVarPath = `/admin/lmslaborTrack/{nodeId}/{employeeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeTrainedLMSCertificate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary find roster assignment and start labor tracking
         * @param {string} nodeId id of sort center
         * @param {string} badgeId Identifier for employee. can be badgeId only.
         * @param {RosterAssignmentInfoRequest} rosterAssignmentInfoRequest Request for finding roster assignment and starting labor tracking(if needed)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startRosterAssignmentLaborTracking: async (nodeId: string, badgeId: string, rosterAssignmentInfoRequest: RosterAssignmentInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('startRosterAssignmentLaborTracking', 'nodeId', nodeId)
            // verify required parameter 'badgeId' is not null or undefined
            assertParamExists('startRosterAssignmentLaborTracking', 'badgeId', badgeId)
            // verify required parameter 'rosterAssignmentInfoRequest' is not null or undefined
            assertParamExists('startRosterAssignmentLaborTracking', 'rosterAssignmentInfoRequest', rosterAssignmentInfoRequest)
            const localVarPath = `/admin/rosterAssignmentlaborTrack/{nodeId}/{badgeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rosterAssignmentInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update SARG role to Calm Code mapping for Node
         * @param {string} nodeId id of sort center
         * @param {Array<MappingInfo>} mappingInfo sarg role to calm code mapping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalmCodeMapping: async (nodeId: string, mappingInfo: Array<MappingInfo>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('updateCalmCodeMapping', 'nodeId', nodeId)
            // verify required parameter 'mappingInfo' is not null or undefined
            assertParamExists('updateCalmCodeMapping', 'mappingInfo', mappingInfo)
            const localVarPath = `/admin/sargRole/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mappingInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update LMS or LMS Custom role Calm Code
         * @param {string} nodeId id of sort center
         * @param {Array<LMSLaborTrackingRole>} lMSLaborTrackingRole LHQ or Custom role to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLMSCalmCodeForLMSCustomRoles: async (nodeId: string, lMSLaborTrackingRole: Array<LMSLaborTrackingRole>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('updateLMSCalmCodeForLMSCustomRoles', 'nodeId', nodeId)
            // verify required parameter 'lMSLaborTrackingRole' is not null or undefined
            assertParamExists('updateLMSCalmCodeForLMSCustomRoles', 'lMSLaborTrackingRole', lMSLaborTrackingRole)
            const localVarPath = `/admin/lmsCertificate/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lMSLaborTrackingRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload roster through FastStart admin UI
         * @param {string} nodeId id of sort center
         * @param {any} [file] 
         * @param {string} [shiftType] 
         * @param {string} [shiftId] 
         * @param {string} [shiftDate] 
         * @param {string} [shiftAdmin] 
         * @param {string} [shiftStart] 
         * @param {string} [shiftEnd] 
         * @param {string} [shiftStartEpoch] 
         * @param {string} [shiftEndEpoch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRoster: async (nodeId: string, file?: any, shiftType?: string, shiftId?: string, shiftDate?: string, shiftAdmin?: string, shiftStart?: string, shiftEnd?: string, shiftStartEpoch?: string, shiftEndEpoch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('uploadRoster', 'nodeId', nodeId)
            const localVarPath = `/admin/uploadRoster/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (shiftType !== undefined) { 
                localVarFormParams.append('shiftType', shiftType as any);
            }
    
            if (shiftId !== undefined) { 
                localVarFormParams.append('shiftId', shiftId as any);
            }
    
            if (shiftDate !== undefined) { 
                localVarFormParams.append('shiftDate', shiftDate as any);
            }
    
            if (shiftAdmin !== undefined) { 
                localVarFormParams.append('shiftAdmin', shiftAdmin as any);
            }
    
            if (shiftStart !== undefined) { 
                localVarFormParams.append('shiftStart', shiftStart as any);
            }
    
            if (shiftEnd !== undefined) { 
                localVarFormParams.append('shiftEnd', shiftEnd as any);
            }
    
            if (shiftStartEpoch !== undefined) { 
                localVarFormParams.append('shiftStartEpoch', shiftStartEpoch as any);
            }
    
            if (shiftEndEpoch !== undefined) { 
                localVarFormParams.append('shiftEndEpoch', shiftEndEpoch as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new LMS Custom Role
         * @param {string} nodeId id of sort center
         * @param {LMSLaborTrackingRole} lMSLaborTrackingRole Custom role to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLMSCustomRoleForSite(nodeId: string, lMSLaborTrackingRole: LMSLaborTrackingRole, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLMSCustomRoleForSite(nodeId, lMSLaborTrackingRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete SARG role to Calm Code mapping for Node
         * @param {string} nodeId id of sort center
         * @param {Array<MappingInfo>} mappingInfo sarg role to calm code mapping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalmCodeMapping(nodeId: string, mappingInfo: Array<MappingInfo>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalmCodeMapping(nodeId, mappingInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete LMSCertificate for Node
         * @param {string} nodeId id of sort center
         * @param {Array<LMSLaborTrackingRole>} lMSLaborTrackingRole LMS role calm code mapping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLmsCertificatesAndCustomRolesForSite(nodeId: string, lMSLaborTrackingRole: Array<LMSLaborTrackingRole>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LMSLaborTrackingRole>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLmsCertificatesAndCustomRolesForSite(nodeId, lMSLaborTrackingRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Admin username through midway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminInfoWithNodeDetails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDetailsWithNodeInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminInfoWithNodeDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get audit details for current shift
         * @param {string} nodeId id of sort center
         * @param {string} [rosterId] id of roster file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditDetailsForCurrentShift(nodeId: string, rosterId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditDetailsForCurrentShift(nodeId, rosterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all possible LMS and Manual Trained certificates for Employee
         * @param {string} nodeId id of sort center
         * @param {string} employeeId Identifier for employee. Can be badgeId or alias.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLMSCertificatesForEmployee(nodeId: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeTrainedLMSCertificates>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLMSCertificatesForEmployee(nodeId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get LMSCertificate and Custom roles for Node
         * @param {string} nodeId id of sort center
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLmsCertificatesAndCustomRolesForSite(nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LMSLaborTrackingRole>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLmsCertificatesAndCustomRolesForSite(nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get SARG role to Calm Code mapping for Node
         * @param {string} nodeId id of sort center
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSargRoleCalmCodeMapping(nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MappingInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSargRoleCalmCodeMapping(nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary start manual labor tracking
         * @param {string} nodeId id of sort center
         * @param {string} employeeId Identifier for employee. Can be badgeId or alias.
         * @param {EmployeeTrainedLMSCertificate} employeeTrainedLMSCertificate Request for starting Manual Labor Tracking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startLMSManualLaborTrackingForEmployee(nodeId: string, employeeId: string, employeeTrainedLMSCertificate: EmployeeTrainedLMSCertificate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startLMSManualLaborTrackingForEmployee(nodeId, employeeId, employeeTrainedLMSCertificate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary find roster assignment and start labor tracking
         * @param {string} nodeId id of sort center
         * @param {string} badgeId Identifier for employee. can be badgeId only.
         * @param {RosterAssignmentInfoRequest} rosterAssignmentInfoRequest Request for finding roster assignment and starting labor tracking(if needed)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startRosterAssignmentLaborTracking(nodeId: string, badgeId: string, rosterAssignmentInfoRequest: RosterAssignmentInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RosterAssignmentInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startRosterAssignmentLaborTracking(nodeId, badgeId, rosterAssignmentInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update SARG role to Calm Code mapping for Node
         * @param {string} nodeId id of sort center
         * @param {Array<MappingInfo>} mappingInfo sarg role to calm code mapping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalmCodeMapping(nodeId: string, mappingInfo: Array<MappingInfo>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalmCodeMapping(nodeId, mappingInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update LMS or LMS Custom role Calm Code
         * @param {string} nodeId id of sort center
         * @param {Array<LMSLaborTrackingRole>} lMSLaborTrackingRole LHQ or Custom role to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLMSCalmCodeForLMSCustomRoles(nodeId: string, lMSLaborTrackingRole: Array<LMSLaborTrackingRole>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLMSCalmCodeForLMSCustomRoles(nodeId, lMSLaborTrackingRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload roster through FastStart admin UI
         * @param {string} nodeId id of sort center
         * @param {any} [file] 
         * @param {string} [shiftType] 
         * @param {string} [shiftId] 
         * @param {string} [shiftDate] 
         * @param {string} [shiftAdmin] 
         * @param {string} [shiftStart] 
         * @param {string} [shiftEnd] 
         * @param {string} [shiftStartEpoch] 
         * @param {string} [shiftEndEpoch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadRoster(nodeId: string, file?: any, shiftType?: string, shiftId?: string, shiftDate?: string, shiftAdmin?: string, shiftStart?: string, shiftEnd?: string, shiftStartEpoch?: string, shiftEndEpoch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadRoster(nodeId, file, shiftType, shiftId, shiftDate, shiftAdmin, shiftStart, shiftEnd, shiftStartEpoch, shiftEndEpoch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new LMS Custom Role
         * @param {string} nodeId id of sort center
         * @param {LMSLaborTrackingRole} lMSLaborTrackingRole Custom role to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLMSCustomRoleForSite(nodeId: string, lMSLaborTrackingRole: LMSLaborTrackingRole, options?: any): AxiosPromise<void> {
            return localVarFp.createLMSCustomRoleForSite(nodeId, lMSLaborTrackingRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete SARG role to Calm Code mapping for Node
         * @param {string} nodeId id of sort center
         * @param {Array<MappingInfo>} mappingInfo sarg role to calm code mapping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalmCodeMapping(nodeId: string, mappingInfo: Array<MappingInfo>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCalmCodeMapping(nodeId, mappingInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete LMSCertificate for Node
         * @param {string} nodeId id of sort center
         * @param {Array<LMSLaborTrackingRole>} lMSLaborTrackingRole LMS role calm code mapping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLmsCertificatesAndCustomRolesForSite(nodeId: string, lMSLaborTrackingRole: Array<LMSLaborTrackingRole>, options?: any): AxiosPromise<Array<LMSLaborTrackingRole>> {
            return localVarFp.deleteLmsCertificatesAndCustomRolesForSite(nodeId, lMSLaborTrackingRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Admin username through midway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminInfoWithNodeDetails(options?: any): AxiosPromise<AdminDetailsWithNodeInfo> {
            return localVarFp.getAdminInfoWithNodeDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get audit details for current shift
         * @param {string} nodeId id of sort center
         * @param {string} [rosterId] id of roster file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditDetailsForCurrentShift(nodeId: string, rosterId?: string, options?: any): AxiosPromise<AuditDetails> {
            return localVarFp.getAuditDetailsForCurrentShift(nodeId, rosterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all possible LMS and Manual Trained certificates for Employee
         * @param {string} nodeId id of sort center
         * @param {string} employeeId Identifier for employee. Can be badgeId or alias.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLMSCertificatesForEmployee(nodeId: string, employeeId: string, options?: any): AxiosPromise<EmployeeTrainedLMSCertificates> {
            return localVarFp.getLMSCertificatesForEmployee(nodeId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get LMSCertificate and Custom roles for Node
         * @param {string} nodeId id of sort center
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLmsCertificatesAndCustomRolesForSite(nodeId: string, options?: any): AxiosPromise<Array<LMSLaborTrackingRole>> {
            return localVarFp.getLmsCertificatesAndCustomRolesForSite(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SARG role to Calm Code mapping for Node
         * @param {string} nodeId id of sort center
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSargRoleCalmCodeMapping(nodeId: string, options?: any): AxiosPromise<Array<MappingInfo>> {
            return localVarFp.getSargRoleCalmCodeMapping(nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary start manual labor tracking
         * @param {string} nodeId id of sort center
         * @param {string} employeeId Identifier for employee. Can be badgeId or alias.
         * @param {EmployeeTrainedLMSCertificate} employeeTrainedLMSCertificate Request for starting Manual Labor Tracking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startLMSManualLaborTrackingForEmployee(nodeId: string, employeeId: string, employeeTrainedLMSCertificate: EmployeeTrainedLMSCertificate, options?: any): AxiosPromise<void> {
            return localVarFp.startLMSManualLaborTrackingForEmployee(nodeId, employeeId, employeeTrainedLMSCertificate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary find roster assignment and start labor tracking
         * @param {string} nodeId id of sort center
         * @param {string} badgeId Identifier for employee. can be badgeId only.
         * @param {RosterAssignmentInfoRequest} rosterAssignmentInfoRequest Request for finding roster assignment and starting labor tracking(if needed)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startRosterAssignmentLaborTracking(nodeId: string, badgeId: string, rosterAssignmentInfoRequest: RosterAssignmentInfoRequest, options?: any): AxiosPromise<RosterAssignmentInfo> {
            return localVarFp.startRosterAssignmentLaborTracking(nodeId, badgeId, rosterAssignmentInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update SARG role to Calm Code mapping for Node
         * @param {string} nodeId id of sort center
         * @param {Array<MappingInfo>} mappingInfo sarg role to calm code mapping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalmCodeMapping(nodeId: string, mappingInfo: Array<MappingInfo>, options?: any): AxiosPromise<void> {
            return localVarFp.updateCalmCodeMapping(nodeId, mappingInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update LMS or LMS Custom role Calm Code
         * @param {string} nodeId id of sort center
         * @param {Array<LMSLaborTrackingRole>} lMSLaborTrackingRole LHQ or Custom role to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLMSCalmCodeForLMSCustomRoles(nodeId: string, lMSLaborTrackingRole: Array<LMSLaborTrackingRole>, options?: any): AxiosPromise<void> {
            return localVarFp.updateLMSCalmCodeForLMSCustomRoles(nodeId, lMSLaborTrackingRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload roster through FastStart admin UI
         * @param {string} nodeId id of sort center
         * @param {any} [file] 
         * @param {string} [shiftType] 
         * @param {string} [shiftId] 
         * @param {string} [shiftDate] 
         * @param {string} [shiftAdmin] 
         * @param {string} [shiftStart] 
         * @param {string} [shiftEnd] 
         * @param {string} [shiftStartEpoch] 
         * @param {string} [shiftEndEpoch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRoster(nodeId: string, file?: any, shiftType?: string, shiftId?: string, shiftDate?: string, shiftAdmin?: string, shiftStart?: string, shiftEnd?: string, shiftStartEpoch?: string, shiftEndEpoch?: string, options?: any): AxiosPromise<void> {
            return localVarFp.uploadRoster(nodeId, file, shiftType, shiftId, shiftDate, shiftAdmin, shiftStart, shiftEnd, shiftStartEpoch, shiftEndEpoch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Create new LMS Custom Role
     * @param {string} nodeId id of sort center
     * @param {LMSLaborTrackingRole} lMSLaborTrackingRole Custom role to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public createLMSCustomRoleForSite(nodeId: string, lMSLaborTrackingRole: LMSLaborTrackingRole, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).createLMSCustomRoleForSite(nodeId, lMSLaborTrackingRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete SARG role to Calm Code mapping for Node
     * @param {string} nodeId id of sort center
     * @param {Array<MappingInfo>} mappingInfo sarg role to calm code mapping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteCalmCodeMapping(nodeId: string, mappingInfo: Array<MappingInfo>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteCalmCodeMapping(nodeId, mappingInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete LMSCertificate for Node
     * @param {string} nodeId id of sort center
     * @param {Array<LMSLaborTrackingRole>} lMSLaborTrackingRole LMS role calm code mapping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteLmsCertificatesAndCustomRolesForSite(nodeId: string, lMSLaborTrackingRole: Array<LMSLaborTrackingRole>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteLmsCertificatesAndCustomRolesForSite(nodeId, lMSLaborTrackingRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Admin username through midway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminInfoWithNodeDetails(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdminInfoWithNodeDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get audit details for current shift
     * @param {string} nodeId id of sort center
     * @param {string} [rosterId] id of roster file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAuditDetailsForCurrentShift(nodeId: string, rosterId?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAuditDetailsForCurrentShift(nodeId, rosterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all possible LMS and Manual Trained certificates for Employee
     * @param {string} nodeId id of sort center
     * @param {string} employeeId Identifier for employee. Can be badgeId or alias.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getLMSCertificatesForEmployee(nodeId: string, employeeId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getLMSCertificatesForEmployee(nodeId, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get LMSCertificate and Custom roles for Node
     * @param {string} nodeId id of sort center
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getLmsCertificatesAndCustomRolesForSite(nodeId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getLmsCertificatesAndCustomRolesForSite(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SARG role to Calm Code mapping for Node
     * @param {string} nodeId id of sort center
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getSargRoleCalmCodeMapping(nodeId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getSargRoleCalmCodeMapping(nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary start manual labor tracking
     * @param {string} nodeId id of sort center
     * @param {string} employeeId Identifier for employee. Can be badgeId or alias.
     * @param {EmployeeTrainedLMSCertificate} employeeTrainedLMSCertificate Request for starting Manual Labor Tracking
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public startLMSManualLaborTrackingForEmployee(nodeId: string, employeeId: string, employeeTrainedLMSCertificate: EmployeeTrainedLMSCertificate, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).startLMSManualLaborTrackingForEmployee(nodeId, employeeId, employeeTrainedLMSCertificate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary find roster assignment and start labor tracking
     * @param {string} nodeId id of sort center
     * @param {string} badgeId Identifier for employee. can be badgeId only.
     * @param {RosterAssignmentInfoRequest} rosterAssignmentInfoRequest Request for finding roster assignment and starting labor tracking(if needed)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public startRosterAssignmentLaborTracking(nodeId: string, badgeId: string, rosterAssignmentInfoRequest: RosterAssignmentInfoRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).startRosterAssignmentLaborTracking(nodeId, badgeId, rosterAssignmentInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update SARG role to Calm Code mapping for Node
     * @param {string} nodeId id of sort center
     * @param {Array<MappingInfo>} mappingInfo sarg role to calm code mapping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateCalmCodeMapping(nodeId: string, mappingInfo: Array<MappingInfo>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).updateCalmCodeMapping(nodeId, mappingInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update LMS or LMS Custom role Calm Code
     * @param {string} nodeId id of sort center
     * @param {Array<LMSLaborTrackingRole>} lMSLaborTrackingRole LHQ or Custom role to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateLMSCalmCodeForLMSCustomRoles(nodeId: string, lMSLaborTrackingRole: Array<LMSLaborTrackingRole>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).updateLMSCalmCodeForLMSCustomRoles(nodeId, lMSLaborTrackingRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload roster through FastStart admin UI
     * @param {string} nodeId id of sort center
     * @param {any} [file] 
     * @param {string} [shiftType] 
     * @param {string} [shiftId] 
     * @param {string} [shiftDate] 
     * @param {string} [shiftAdmin] 
     * @param {string} [shiftStart] 
     * @param {string} [shiftEnd] 
     * @param {string} [shiftStartEpoch] 
     * @param {string} [shiftEndEpoch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public uploadRoster(nodeId: string, file?: any, shiftType?: string, shiftId?: string, shiftDate?: string, shiftAdmin?: string, shiftStart?: string, shiftEnd?: string, shiftStartEpoch?: string, shiftEndEpoch?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).uploadRoster(nodeId, file, shiftType, shiftId, shiftDate, shiftAdmin, shiftStart, shiftEnd, shiftStartEpoch, shiftEndEpoch, options).then((request) => request(this.axios, this.basePath));
    }
}


