import AdminLayout from "./layout/AdminLayout";
import Routes from "./routes/Routes";
import { AdminContextProvider } from "../store/admin-context";

export default function App() {
  return (
    <AdminContextProvider>
      <AdminLayout>
        <Routes />
      </AdminLayout>
    </AdminContextProvider>
  );
}
