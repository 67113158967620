import React from "react";
import Success from "./Success";
import Error from "./Error";
import {STATUS_SUCCESS} from "../../../constants";

export default function ScanResult (props){
    if (STATUS_SUCCESS === props.message) {
        return (
            <Success
                rosterShiftInfo={props.rosterShiftInfo}
                badgeScanAssignmentInfo={props.badgeScanAssignmentInfo}
            />
        );
    }
    return (
        <Error
            message={props.message}
            status={props.status}
        />
    );
};