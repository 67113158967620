import React, {useEffect, useState, useCallback} from "react";
import Table, {
    TableRow,
    TableCell,
    TableActionBar,
} from "@amzn/meridian/table";
import {TableSortDirection} from "@amzn/meridian/table/table";
import Pagination from "@amzn/meridian/pagination";
import Column from "@amzn/meridian/column";
import filterTokenIcon from "@amzn/meridian-tokens/base/icon/filter";
import Icon from "@amzn/meridian/icon";
import Input from "@amzn/meridian/input";
import helpKnockoutTokens from "@amzn/meridian-tokens/base/icon/help-knockout";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";
import {isEmpty} from 'lodash'
import CalmCodeSubmitModal from "../../modal/CalmCodeSubmitModal";
import {
    DEFAULT_SORT_COLUMN,
    DEFAULT_SORT_DIRECTION,
    DELAY_IN_MINUTES_TOOLTIP_TEXT,
    CALM_CODE_PROCESSING_MESSAGE,
    CALM_CODE_SUBMIT_ERROR_MESSAGE,
    DELAY_IN_MIN_LABEL,
    ALERT_AUTHENTICATION_MESSAGE, DEFAULT_ITEMS_PER_PAGE,
} from "../../../constants";
import AdminApiFactory from "../../../admin-api/AdminApiFactory";
import orderBy from "lodash/orderBy";
import {MappingInfo} from "../../../admin-api/generated-src";
import CalmCodeRow from "./CalmCodeRow";


export default function CalmCodeTable({sargRoleList, titles, showRoleType}) {
    const [userSargRoles, setUserSargRoles] = useState(sargRoleList);
    const [roleNameFilter, setRoleNameFilter] = useState("");
    const [calmCodeNameFilter, setCalmCodeNameFilter] = useState("");
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState<TableSortDirection>(
        DEFAULT_SORT_DIRECTION
    );
    const [openModal, setOpenModal] = useState(false);
    const [responseMessage, setResponseMessage] = useState<any>();
    const itemsPerPage = DEFAULT_ITEMS_PER_PAGE;
    const [numberOfPages, setNumberOfPages] = useState(Math.ceil(sargRoleList.length / itemsPerPage));
    const [currentPage, setCurrentPage] = useState(1);
    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex = firstVisibleIndex + itemsPerPage;

    const AdminApi = AdminApiFactory();

    useEffect(() => {
        setUserSargRoles(sargRoleList);
        return () => setUserSargRoles([]);
    }, [sargRoleList]);



    const submitHandler = (role) => {
        let updateRole: MappingInfo[] = [role];
        if (userSargRoles && userSargRoles.length) {
            setOpenModal(true);
            setResponseMessage(CALM_CODE_PROCESSING_MESSAGE);
            const {nodeId} = userSargRoles[0];
            (async () => {
                try {
                    const updateCalmCodeResponse = (
                        await AdminApi.updateCalmCodeMapping(nodeId, updateRole)
                    ).data as unknown;
                    // setResponseMessage will be processed later inside Modal component
                    setResponseMessage(updateCalmCodeResponse);
                } catch (ex) {
                    console.error(
                        `Error posting calm code to /admin/sargRole/${nodeId}`,
                        ex.response
                    );
                    if (ex.response && ex.response.status === 400){
                        setResponseMessage(ex.response.data);
                    }
                    else if (ex.response && ex.response.status === 403) {
                        setResponseMessage(ALERT_AUTHENTICATION_MESSAGE);
                    } else {
                        setResponseMessage(CALM_CODE_SUBMIT_ERROR_MESSAGE);
                    }
                }
            })();
        }
    };

    const deleteConfirmHandler = (deletedRole) => {
        if (userSargRoles && userSargRoles.length) {
            const {nodeId} = userSargRoles[0];
            (async () => {
                try {
                    const updateCalmCodeResponse = (
                        await AdminApi.deleteCalmCodeMapping(nodeId, [deletedRole])
                    ).data as unknown;
                    // setResponseMessage will be processed later inside Modal component
                    setResponseMessage(updateCalmCodeResponse);
                    setUserSargRoles(
                        userSargRoles.filter((role) =>
                            role.sargRoleUniqueId !== deletedRole.sargRoleUniqueId
                        )
                    );
                } catch (ex) {
                    console.error(
                        `Error posting calm code to /admin/sargRole/${nodeId}`,
                        ex
                    );
                    if (ex.response && ex.response.status === 403) {
                        setResponseMessage(ALERT_AUTHENTICATION_MESSAGE);
                    } else {
                        setResponseMessage(CALM_CODE_SUBMIT_ERROR_MESSAGE);
                    }
                }
            })();
        }
    };

    const onSortHandler = (event) => {
        setUserSargRoles((
            orderBy(
                userSargRoles,
                [event.sortColumn],
                [event.sortDirection === "ascending" ? "asc" : "desc"]
            )
        ));
        setSortColumn(event.sortColumn);
        setSortDirection(event.sortDirection);
    };

    const onConfirmClose = useCallback(() => {
        setOpenModal(false);
        history.go(0);
    }, []);


    return (
        <Column>
            <CalmCodeSubmitModal
                open={openModal}
                responseMessage={responseMessage}
                onClose={onConfirmClose}
            />
            <Table
                headerRows={1}
                rowComponents={[TableRow, CalmCodeRow]}
                showDividers
                fixHeaderRows
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                onSort={onSortHandler}
            >
                <TableActionBar>
                    <Input
                        key="searchInputRoleName"
                        prefix = "RoleName"
                        type="text"
                        value={roleNameFilter}
                        placeholder="Filter by Role Name"
                        prefixIconTokens={filterTokenIcon}
                        onChange={setRoleNameFilter}
                        id="filterByRoleSearchInput"
                    />
                    <Input
                        key="searchInputCalmCode"
                        prefix = "CalmCode"
                        type="text"
                        value={calmCodeNameFilter}
                        placeholder="Filter by calmcode "
                        prefixIconTokens={filterTokenIcon}
                        onChange={setCalmCodeNameFilter}
                        id="filterByCalmCodeSearchSearchInput"
                    />
                </TableActionBar>
                <TableRow key="calmCodeTitle">
                    {titles.map(({headerName, sortId, columnSpan}, index) => {
                        if (headerName === DELAY_IN_MIN_LABEL) {
                            return (
                                <TableCell key={headerName} header={true} sortColumn={sortId}>
                                    <Tooltip
                                        key={`${headerName}-tooltip`}
                                        position="top"
                                        title={DELAY_IN_MINUTES_TOOLTIP_TEXT}
                                    >
                                        <Text type="h100">
                                            {headerName + " "}
                                            <Icon tokens={helpKnockoutTokens}/>
                                        </Text>
                                    </Tooltip>
                                </TableCell>
                            );
                        } else {
                            return (
                                <TableCell columnSpan={columnSpan} key={headerName} sortColumn={sortId}>
                                    {headerName}
                                </TableCell>
                            );
                        }
                    })}
                </TableRow>
                {userSargRoles
                    .filter(({calmCode}) =>
                        !isEmpty(calmCodeNameFilter) ? calmCode.toLowerCase().includes(calmCodeNameFilter.toLocaleLowerCase()) : true
                    )
                    .filter(({roleName}) =>
                        !isEmpty(roleNameFilter) ? roleName.toLowerCase().includes(roleNameFilter.toLocaleLowerCase()) : true
                    )
                    .slice(firstVisibleIndex, lastVisibleIndex)
                    .map(
                        (role) => (
                            <CalmCodeRow
                                key={role.sargRoleUniqueId}
                                role={role}
                                handleSaveClick={submitHandler}
                                handleDeleteClick={deleteConfirmHandler}
                                showRoleType={showRoleType}/>
                        )
                    )}
            </Table>
            <Pagination
                showSkipArrows={true}
                numberOfPages={numberOfPages}
                onChange={setCurrentPage}
                currentPage={currentPage}
            />
        </Column>
    );
}
