import React from "react";
import Button from "@amzn/meridian/button";
import Modal from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Heading from "@amzn/meridian/heading";
import Column from "@amzn/meridian/column";
import { 
  FASTSTART_WIKI_LINK, 
  FEATURE_REQUEST_LINK, 
  PERMISSIONS_REQUEST_LINK, 
  TECHNICAL_ISSUE_LINK 
} from "../../constants";

export default function NeedAssistanceModal({ open, onClose}) {
  const headingText = "Need Assistance?";
  const descriptionLine1 = "For additional information please view the wiki";
  const descriptionLine2 = "Select an option below to ensure your ticket gets to the right team";
  const wikiLinkText = "For documentation and usage instructions please refer to the wiki";
  const technicalIssueText = "Time outs? Errors? Unexpected behaviour? Report here:";
  const featureRequestText = "Have an idea that can make Fast Start better?";
  const permissionsRequestText = "Need Permissions to access secured areas of Fast Start?";

  return (
    <React.Fragment>
      <Modal
        open={open}
        title=""
        onClose={onClose}
        scrollContainer="viewport"
        bodySpacingInset={"500"}
      >
        <Heading level={2}>{headingText}</Heading>
        <br />
        <Text>{descriptionLine1}</Text>
        <Text>{descriptionLine2}</Text>
        <br />
        <Column spacing={"500"}>
          <hr />
          <Row spacing={"600"} widths={["50%", "50%"]}>
            <Column>
              <Text>{wikiLinkText}</Text>
            </Column>
            <Column>
              <Button 
                href={FASTSTART_WIKI_LINK} 
                target="_blank" 
                rel="noopener noreferrer" 
                type="tertiary"
              > 
                View Wiki 
              </Button>
            </Column>
          </Row>
          <hr />
          <Row spacing={"600"} widths={["50%", "50%"]}>
            <Column>
              <Text>{technicalIssueText}</Text>
            </Column>
            <Column>
              <Button 
                href={TECHNICAL_ISSUE_LINK} 
                target="_blank" 
                rel="noopener noreferrer" 
                type="tertiary"
              > 
                Technical Issue
              </Button>
            </Column>
          </Row>
          <Row spacing={"600"} widths={["50%", "50%"]}>
            <Column>
              <Text>{featureRequestText}</Text>
            </Column>
            <Column>
              <Button 
                href={FEATURE_REQUEST_LINK} 
                target="_blank" 
                rel="noopener noreferrer" 
                type="tertiary"
              > 
                Feature Request
              </Button>
            </Column>
          </Row>
          <Row spacing={"600"} widths={["50%", "50%"]}>
            <Column>
              <Text>{permissionsRequestText}</Text>
            </Column>
            <Column>
              <Button 
                href={PERMISSIONS_REQUEST_LINK} 
                target="_blank" 
                rel="noopener noreferrer" 
                type="tertiary"
              > 
                Permissions Request
            </Button>
            </Column>
          </Row>
        </Column>
      </Modal>
    </React.Fragment>
  );
}