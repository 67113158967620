import React from "react";
import Tooltip from "@amzn/meridian/tooltip";
import "./AuditComponents.scss";

export default function TextWithTooltip(props) {
  return <Tooltip position="top" title={props.tooltipText}>
    <span className={props.textClass} >
      <p> {props.value}</p>
    </span>
  </Tooltip>
}