import React, { useEffect, useState, useCallback } from "react";
import Table, {
    TableRow,
    TableCell,
    TableActionBar,
} from "@amzn/meridian/table";
import Pagination from "@amzn/meridian/pagination";
import { TableSortDirection } from "@amzn/meridian/table/table";
import Column from "@amzn/meridian/column";
import filterTokenIcon from "@amzn/meridian-tokens/base/icon/filter";
import Icon from "@amzn/meridian/icon";
import Input from "@amzn/meridian/input";
import helpKnockoutTokens from "@amzn/meridian-tokens/base/icon/help-knockout";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";
import {isEmpty} from 'lodash'
import CalmCodeSubmitModal from "../../modal/CalmCodeSubmitModal";
import {
    DEFAULT_SORT_COLUMN,
    DEFAULT_SORT_DIRECTION,
    DELAY_IN_MINUTES_TOOLTIP_TEXT,
    CALM_CODE_PROCESSING_MESSAGE,
    CALM_CODE_SUBMIT_ERROR_MESSAGE,
    CALM_CODE_LABEL,
    CALM_CODE_FIELD_NAME,
    DELAY_IN_MIN_LABEL,
    DELAY_IN_MIN_FIELD_NAME,
    ALERT_AUTHENTICATION_MESSAGE,
    CERTIFICATE_NAME_LABEL,
    CERTIFICATE_NAME_FIELD_NAME, ACTION_LABEL, CUSTOM_CERTIFICATE_DELETE_TOOLTIP_TEXT, DEFAULT_ITEMS_PER_PAGE,
} from "../../../constants";
import AdminApiFactory from "../../../admin-api/AdminApiFactory";
import orderBy from "lodash/orderBy";
import LMSCertificateRow from "./LMSCertificateRow";
import {LMSLaborTrackingRole} from "../../../admin-api/generated-src";

const LMS_TABLE_TITLES = [
    { headerName: CERTIFICATE_NAME_LABEL, sortId: CERTIFICATE_NAME_FIELD_NAME },
    { headerName: CALM_CODE_LABEL, sortId: CALM_CODE_FIELD_NAME },
    {headerName: DELAY_IN_MIN_LABEL, sortId: DELAY_IN_MIN_FIELD_NAME,},
    {
        headerName: ACTION_LABEL,
        sortId: '',
        columnSpan: 2,
    }
];

export default function LMSTable({ lmsCertificateList }) {
    const [userLmsCertificateList, setUserLmsCertificateList] = useState(lmsCertificateList);
    const [CertificateNameFilter, setCertificateNameFilter] = useState("");
    const [calmCodeNameFilter, setCalmCodeNameFilter] = useState("");
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState<TableSortDirection>(
        DEFAULT_SORT_DIRECTION
    );
    const [openModal, setOpenModal] = useState(false);
    const [responseMessage, setResponseMessage] = useState<any>();
    const itemsPerPage = DEFAULT_ITEMS_PER_PAGE;
    const [numberOfPages, setNumberOfPages] = useState(Math.ceil(lmsCertificateList.length / itemsPerPage));
    const [currentPage, setCurrentPage] = useState(1);
    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex = firstVisibleIndex + itemsPerPage;

    const AdminApi = AdminApiFactory();

    // need this effect to "listen" to parent state change
    useEffect(() => {
        setUserLmsCertificateList(lmsCertificateList);
        return () => setUserLmsCertificateList([]);
    }, [lmsCertificateList]);

    const onConfirmClose = useCallback(() => {
        setOpenModal(false);
        history.go(0);
    }, []);

    const onSortHandler = (event) => {
        setUserLmsCertificateList(
            orderBy(
                userLmsCertificateList,
                [event.sortColumn],
                [event.sortDirection === "ascending" ? "asc" : "desc"]
            )
        );
        setSortColumn(event.sortColumn);
        setSortDirection(event.sortDirection);
    };

    const submitHandler = (modifiedCertificate) => {
        if (userLmsCertificateList && userLmsCertificateList.length) {
            setOpenModal(true);
            setResponseMessage(CALM_CODE_PROCESSING_MESSAGE);
            const { nodeId } = userLmsCertificateList[0];
            (async () => {
                try {
                    const updateCalmCodeResponse = (
                        await AdminApi.updateLMSCalmCodeForLMSCustomRoles(
                            nodeId,
                            [modifiedCertificate]
                        )
                    ).data as unknown;
                    // setResponseMessage will be processed later inside Modal component
                    setResponseMessage(updateCalmCodeResponse);
                } catch (ex) {
                    console.error(
                        `Error posting calm code to /admin/LMSCertificate/${nodeId}`,
                        ex
                    );
                    if (ex.response && ex.response.status === 400){
                        setResponseMessage(ex.response.data);
                    }
                    else if (ex.response && ex.response.status === 403) {
                        setResponseMessage(ALERT_AUTHENTICATION_MESSAGE);
                    } else {
                        setResponseMessage(CALM_CODE_SUBMIT_ERROR_MESSAGE);
                    }
                }
            })();
        }
    };

    const deleteConfirmHandler = (deletedCertificate) => {
        if (lmsCertificateList && lmsCertificateList.length) {
            const {nodeId} = lmsCertificateList[0];
            (async () => {
                try {
                    const updateCalmCodeResponse = (
                        await AdminApi.deleteLmsCertificatesAndCustomRolesForSite(nodeId, [deletedCertificate])
                    ).data as unknown;
                    // setResponseMessage will be processed later inside Modal component
                    setResponseMessage(updateCalmCodeResponse);
                    setUserLmsCertificateList(
                        lmsCertificateList.filter((certificate) =>
                            certificate.certificateId !== deletedCertificate.certificateId
                        )
                    );
                } catch (ex) {
                    console.error(
                        `Error posting calm code to /admin/sargRole/${nodeId}`,
                        ex
                    );
                    if (ex.response && ex.response.status === 403) {
                        setResponseMessage(ALERT_AUTHENTICATION_MESSAGE);
                    } else {
                        setResponseMessage(CALM_CODE_SUBMIT_ERROR_MESSAGE);
                    }
                }
            })();
        }
    };

    return (
        <Column>
            <CalmCodeSubmitModal
                open={openModal}
                responseMessage={responseMessage}
                onClose={onConfirmClose}
            />
            <Table
                headerRows={1}
                rowComponents={[TableRow, LMSCertificateRow]}
                showDividers
                fixHeaderRows
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                onSort={onSortHandler}
            >
                <TableActionBar>
                    <Input
                        key="searchInputCertificateName"
                        prefix = "CertificateName"
                        type="text"
                        value={CertificateNameFilter}
                        placeholder="Filter by Certificate Name"
                        prefixIconTokens={filterTokenIcon}
                        onChange={setCertificateNameFilter}
                        id="filterByCertificateSearchInput"
                    />
                    <Input
                        key="searchInputCalmCode"
                        prefix = "CalmCode"
                        type="text"
                        value={calmCodeNameFilter}
                        placeholder="Filter by calmcode "
                        prefixIconTokens={filterTokenIcon}
                        onChange={setCalmCodeNameFilter}
                        id="filterByCalmCodeSearchSearchInput"
                    />
                </TableActionBar>
                <TableRow key="calmCodeTitle">
                    {LMS_TABLE_TITLES.map(({ headerName, sortId, columnSpan }, index) => {
                        if ((headerName === DELAY_IN_MIN_LABEL)) {
                            return (
                                <TableCell
                                    key={headerName}
                                    columnSpan={columnSpan}
                                    header={true}
                                    sortColumn={sortId}>
                                    <Tooltip
                                        key={`${headerName}-tooltip`}
                                        position="top"
                                        title={DELAY_IN_MINUTES_TOOLTIP_TEXT}
                                    >
                                        <Text type="h100">
                                            {headerName + " "}
                                            <Icon tokens={helpKnockoutTokens} />
                                        </Text>
                                    </Tooltip>
                                </TableCell>
                            );
                        }
                        else if ((headerName === ACTION_LABEL)) {
                            return (
                                <TableCell
                                    key={headerName}
                                    columnSpan={columnSpan}
                                    header={true}
                                    sortColumn={sortId}>
                                    <Tooltip
                                        key={`${headerName}-tooltip`}
                                        position="top"
                                        title={CUSTOM_CERTIFICATE_DELETE_TOOLTIP_TEXT}
                                    >
                                        <Text type="h100">
                                            {headerName + " "}
                                            <Icon tokens={helpKnockoutTokens} />
                                        </Text>
                                    </Tooltip>
                                </TableCell>
                            );
                        }
                        else {
                            return (
                                <TableCell columnSpan={columnSpan} key={headerName} sortColumn={sortId}>
                                    {headerName}
                                </TableCell>
                            );
                        }
                    })}
                </TableRow>
                {userLmsCertificateList
                    .filter(({calmCode}) =>
                        !isEmpty(calmCodeNameFilter) ? calmCode.toLowerCase().includes(calmCodeNameFilter.toLocaleLowerCase()) : true
                    )
                    .filter(({ certificateName }) =>
                        CertificateNameFilter ? certificateName.toLowerCase().includes(CertificateNameFilter.toLocaleLowerCase()) : true
                    )
                    .slice(firstVisibleIndex, lastVisibleIndex)
                    .map(
                        ( certificate) => (
                            <LMSCertificateRow
                                key={certificate.certificateName}
                                certificate={certificate}
                                handleSaveClick={submitHandler}
                                handleDeleteClick={deleteConfirmHandler}
                            />)
                    )}
            </Table>
            <Pagination
                showSkipArrows={true}
                numberOfPages={numberOfPages}
                onChange={setCurrentPage}
                currentPage={currentPage}
            />
        </Column>
    );
}
