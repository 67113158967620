import React, {useContext, useEffect, useState} from "react";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import GreenCheckmarkWithTooltip from "./GreenCheckMarkWithTooltip";
import RedCrossWithTooltip from "./RedCrossWithTooltip";
import YellowBulbWithTooltip from "./YellowBulbWithTooltip";
import RedAlertWithTooltip from "./RedAlertWithTooltip";
import AuditContext from "../../store/audit-store";
import RosterStatsRow from "./RosterStatsRow";
import {
  COMPLETED_STATUS,
  INVALID_STATUS, OVERRIDDEN_STATUS, ROW_COLOR_GREEN,
  ROW_COLOR_GREY,
  ROW_COLOR_RED, ROW_COLOR_YELLOW,
  SCHEDULED_STATUS, STATUS_TEXT_GREEN,
  STATUS_TEXT_GREY,
  STATUS_TEXT_RED, STATUS_TEXT_YELLOW
} from "../../constants";

export default function AuditStatsRow() {
  const auditContext = useContext(AuditContext);
  const { auditStats, rosterIdList, currentRosterId  } = auditContext;
  const { kioskScannedCount,
    kioskUnScannedCount,
    kioskLaborTrackCount,
    kioskNonLaborTrackCount,
    midShiftScannedCount,
    midShiftLaborTrackCount,
    midShiftNonLaborTrackCount
  } = auditStats

  const [status, setStatus] = useState(INVALID_STATUS);
  const [textClass, setTextClass] = useState(STATUS_TEXT_RED);
  const [rowClass, setRowClass] = useState(ROW_COLOR_RED);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  useEffect(() => {
    const nodeRosterIdMappingInfo = rosterIdList.find( function (roster) {
      return roster.rosterId === currentRosterId
    })
    if (nodeRosterIdMappingInfo === undefined) {
      setStatus('Invalid')
      setTextClass('statusTextRed')
      setRowClass('rowColorRed')
    } else {
      // @ts-ignore
      setStatus(nodeRosterIdMappingInfo.rosterStatus)
      // @ts-ignore
      setStartTime(nodeRosterIdMappingInfo.rosterStartTime)
      // @ts-ignore
      setEndTime(nodeRosterIdMappingInfo.rosterEndTime)
      if (nodeRosterIdMappingInfo.rosterStatus === SCHEDULED_STATUS) {
        setTextClass(STATUS_TEXT_GREY)
        setRowClass(ROW_COLOR_GREY)
      } else if (nodeRosterIdMappingInfo.rosterStatus == COMPLETED_STATUS
          || nodeRosterIdMappingInfo.rosterStatus === OVERRIDDEN_STATUS) {
        setTextClass(STATUS_TEXT_GREEN)
        setRowClass(ROW_COLOR_GREEN)
      } else {
        setTextClass(STATUS_TEXT_YELLOW)
        setRowClass(ROW_COLOR_YELLOW)
      }
    }
  })

  // TODO: can refactor to auto-generate from objects
  return <Row spacing="medium large" className={rowClass} widths={["30%","40%","30%"]}>
    <Column>
      <Row spacing="medium medium" alignmentHorizontal="center">
        <Row spacing="small">
          <GreenCheckmarkWithTooltip tooltipText="#Badged In" /><Text type="h300">{kioskScannedCount} |</Text>
        </Row>
        <Row spacing="small">
          <RedCrossWithTooltip /><Text type="h300"> {kioskUnScannedCount} | </Text>
        </Row>
        <Row spacing="small">
          <YellowBulbWithTooltip tooltipText="#Auto Labor Tracked" /><Text type="h300"> {kioskLaborTrackCount} |</Text>
        </Row>
        <Row spacing="small">
          <RedAlertWithTooltip tooltipText="#Not Auto Labor Tracked" /><Text type="h300"> {kioskNonLaborTrackCount}</Text>
        </Row>
      </Row>
      <Row alignmentHorizontal="center">
        <Text>Kiosk Scans</Text>
      </Row>
    </Column>
    <RosterStatsRow status={status} startTime={startTime} endTime={endTime} textClass={textClass}/>
    <Column>
      <Row spacing="medium medium" alignmentHorizontal="center">
        <Row spacing="small">
          <GreenCheckmarkWithTooltip tooltipText="#Mid-Shift Moves" /><Text type="h300"> {midShiftScannedCount} |</Text>
        </Row>
        <Row spacing="small">
          <YellowBulbWithTooltip tooltipText="#Manual Labor Tracked" /><Text type="h300"> {midShiftLaborTrackCount} |</Text>
        </Row>
        <Row spacing="small">
          <RedAlertWithTooltip tooltipText="#Not Manual Labor Tracked" /><Text type="h300"> {midShiftNonLaborTrackCount}</Text>
        </Row>
      </Row>
      <Row alignmentHorizontal="center">
        <Text>Manual Labor Track Scans</Text>
      </Row>
    </Column>
  </Row>
}