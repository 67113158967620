import {Route, Switch} from "react-router-dom";
import Homepage from "../home";
import CalmCodePage from "../configurationPage/assignedRoleCalmCode/CalmCodePage";
import UploadRosterPage from "../configurationPage/uploadRoster/UploadRosterPage";
import AuditPage from "../audit/AuditPage";
import {LMSTableContextProvider} from "../../store/lms-store";
import AddCertificatesPage from "../configurationPage/addCertificates/AddCertificatePage";
import {LMSManualLaborTrackContextProvider} from "../../store/lms-manual-labor-track-store";
import LMSManualLaborTrackPage from "../lmsManualLaborTrack/LMSManualLaborTrackPage";
import {AuditContextProvider} from "../../store/audit-store";
import KioskLaborTrackPage from "../kiosk/KioskLaborTrackPage";
import StatusPage from "../kiosk/status/StatusPage";

export default function Routes() {
    return (
        <Switch>
            <Route exact path={["/", "/:nodeId"]}>
                <Homepage/>
            </Route>
            <Route exact path="/:nodeId/config/calm-code" component={CalmCodePage}/>
            <Route exact path="/:nodeId/config/upload-roster" component={UploadRosterPage}/>
            <Route exact path="/:nodeId/config/add-certificates">
                <LMSTableContextProvider>
                    <AddCertificatesPage/>
                </LMSTableContextProvider>
            </Route>
            <Route exact path="/:nodeId/kiosk-labor-track" component={KioskLaborTrackPage}/>
            <Route exact path="/:nodeId/kiosk-labor-track/:badgeId/status" component={StatusPage}/>
            <Route exact
                   path="/:nodeId/lms-manual-labor-track"
            >
                <LMSManualLaborTrackContextProvider>
                    <LMSManualLaborTrackPage/>
                </LMSManualLaborTrackContextProvider>
            </Route>
            <Route exact path="/:nodeId/audit">
                <AuditContextProvider>
                    <AuditPage/>
                </AuditContextProvider>
            </Route>
            <Route path="/" component={Homepage}/>
        </Switch>
    );
}
