import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SideMenu, { SideMenuLink } from "@amzn/meridian/side-menu";
import homeIconTokens from "@amzn/meridian-tokens/base/icon/home";
import cogIconTokens from "@amzn/meridian-tokens/base/icon/cog";
import dashboardIconTokens from "@amzn/meridian-tokens/base/icon/dashboard";
import clockIconTokens from "@amzn/meridian-tokens/base/icon/clock";
import {
  HOME_LINK_LABEL,
  CONFIGURATION_LINK_LABEL,
  CONFIGURATION_CALM_CODE_LABEL,
  SIDE_MENU_HASH_KEY,
  SIDE_MENU_OPEN_HASH_KEY,
  AUDIT_LINK_LABEL,
  CONFIGURATION_ADD_CERTIFICATE_LABEL,
  LMS_MANUAL_LABOR_TRACK_LINK_LABEL,
  KIOSK_LABOR_TRACK_LINK_LABEL, CONFIGURATION_UPLOAD_ROSTER_LABEL,
} from "../../constants";
import AdminContext from "../../store/admin-context";


const NAVLINK_HASH_LMS = {
  home: "#home",
  configuration: "#configuration",
  calmCode: "#calmCode",
  addCertificates: "#addCertificates",
  lmsManualLaborTrack: "#lmsManualLaborTrack",
  audit: "#audit",
  kiosk: "#kioskLaborTrack",
  uploadRoster: "#uploadRoster",
};


const NAVLINK_HASH_OBJECTS_LMS = [
  {
    hashName: NAVLINK_HASH_LMS.home,
    label: HOME_LINK_LABEL,
    iconTokens: homeIconTokens,
    isNested: false,
    hrefLink: "/",
  },
  {
    hashName: NAVLINK_HASH_LMS.configuration,
    label: CONFIGURATION_LINK_LABEL,
    iconTokens: cogIconTokens,
    isNested: true,
    hrefLink: "/config",
    nestedLinks: [
      {
        hashName: NAVLINK_HASH_LMS.calmCode,
        label: CONFIGURATION_CALM_CODE_LABEL,
        hrefLink: "/calm-code",
      },
      {
        hashName: NAVLINK_HASH_LMS.addCertificates,
        label: CONFIGURATION_ADD_CERTIFICATE_LABEL,
        hrefLink: "/add-certificates",
      },
      {
        hashName: NAVLINK_HASH_LMS.uploadRoster,
        label: CONFIGURATION_UPLOAD_ROSTER_LABEL,
        hrefLink: "/upload-roster",
      }
    ]
  },
  {
    hashName: NAVLINK_HASH_LMS.kiosk,
    label: KIOSK_LABOR_TRACK_LINK_LABEL,
    iconTokens: dashboardIconTokens,
    isNested: false,
    hrefLink: "/kiosk-labor-track"
  },
  {
    hashName: NAVLINK_HASH_LMS.lmsManualLaborTrack,
    label: LMS_MANUAL_LABOR_TRACK_LINK_LABEL,
    iconTokens: dashboardIconTokens,
    isNested: false,
    hrefLink: "/lms-manual-labor-track"
  },
  {
    hashName: NAVLINK_HASH_LMS.audit,
    label: AUDIT_LINK_LABEL,
    iconTokens: clockIconTokens,
    isNested: false,
    hrefLink: "/audit"
  }
];


const PATH_NAMES_HASH_ARRAY_LMS = [{
  hrefLink: "/audit",
  hashName: NAVLINK_HASH_LMS.audit,
},

  {
    hrefLink: "/lms-manual-labor-track",
    hashName: NAVLINK_HASH_LMS.lmsManualLaborTrack,
  },
  {
    hrefLink: "/add-certificates",
    hashName: NAVLINK_HASH_LMS.addCertificates,
  },
  {
    hrefLink: "/calm-code",
    hashName: NAVLINK_HASH_LMS.calmCode,
  },
];



export default function AMZLAdminSideMenu() {
  const location = useLocation();
  const history = useHistory();
  const [hash, setHash] = useState(getDefaultHash(location));
  const [openLinkHash, setOpenLinkHash] = useState(localStorage.getItem(SIDE_MENU_OPEN_HASH_KEY) || null);

  function getDefaultHash(location) {
    let localStoredHash = localStorage.getItem(SIDE_MENU_HASH_KEY);
    let locationHash = getLocationHashFromPath(location.pathname);
    if (localStoredHash === locationHash) {
      return localStoredHash;
    } else {
      localStorage.setItem(SIDE_MENU_HASH_KEY, locationHash);
      return locationHash;
    }
  }

  function getLocationHashFromPath(pathname) {

    for (let linkItem of PATH_NAMES_HASH_ARRAY_LMS) {
      if (pathname.includes(linkItem.hrefLink)) {
        return linkItem.hashName;
      }
    }

    return NAVLINK_HASH_LMS.home;
  }


  const adminContext = useContext(AdminContext);
  const { selectedNode, isSideBarOpen } = adminContext;

  return (
      <SideMenu open={isSideBarOpen}>
        {NAVLINK_HASH_OBJECTS_LMS.map(({isNested, hashName, label, iconTokens, hrefLink, nestedLinks = []}) => {
          if (isNested) {
            return <SideMenuLink
                key={hashName}
                href={hashName}
                selected={hash === hashName}
                open={openLinkHash === hashName}
                onClick={(hashValue) => {
                  setHash(hashValue);
                  localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                  let nextOpenLinkHash = openLinkHash === hashName ? null : hashValue;
                  setOpenLinkHash(nextOpenLinkHash);
                  localStorage.setItem(SIDE_MENU_OPEN_HASH_KEY, nextOpenLinkHash);
                }}
                disabled={!selectedNode}
                icon={iconTokens}
            >
              {label}
              {nestedLinks.map((nestedLink) => (
                  <SideMenuLink
                      key={nestedLink.hashName}
                      href={nestedLink.hashName}
                      selected={hash === nestedLink.hashName}
                      disabled={!selectedNode}
                      onClick={(hashValue) => {
                        setHash(hashValue);
                        localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                        history.push(`/${selectedNode}${hrefLink}${nestedLink.hrefLink}`);
                      }}
                  >
                    {nestedLink.label}
                  </SideMenuLink>
              ))}
            </SideMenuLink>
          } else {
            return <SideMenuLink
                key={hashName}
                href={hashName}
                selected={hash === hashName}
                onClick={(hashValue) => {
                  setHash(hashValue);
                  localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                  history.push(selectedNode ? `/${selectedNode}${hrefLink}` : "/");
                }}
                disabled={hashName === NAVLINK_HASH_LMS.home ? false : !selectedNode}
                icon={iconTokens}
            >{label}</SideMenuLink>
          }
        })}
      </SideMenu>
  );
}