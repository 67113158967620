import React from "react";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import "./Title.scss";
import logo from "../../assets/faststart_100.png";

export default function Title(props) {
    const titleNodeId = props.nodeId ? props.nodeId.toUpperCase() : "";
    return (
        <div>
            <Row width="100%" alignmentHorizontal="center">
                <img src={logo} alt="Logo" />
            </Row>
            <Row alignmentHorizontal="center">
                <Box>
                    <h1 className="nodeId">{titleNodeId}</h1>
                </Box>
                <Box>
                    <h1 className="fastStart">FastStart</h1>
                </Box>
            </Row>
        </div>
    );
};