import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select, { SelectOption } from "@amzn/meridian/select";
import AdminContext from "../../store/admin-context";
import { NODE_ID_DROPDOWN_PLACEHOLDER } from "../../constants";

export default function NodeIdSelect({ width = 200, label = "" }) {
  const adminContext = useContext(AdminContext);
  const { selectedNode, changeSelectedNode, nodeList } = adminContext;

  const history = useHistory();

  function onChangeNodeIdHandler(nodeValue) {
    changeSelectedNode(nodeValue);
    history.push(`/${nodeValue}`);
  }

  return (
    <Select
      data-testid="nodeIdSelect"
      value={selectedNode}
      onChange={onChangeNodeIdHandler}
      placeholder={NODE_ID_DROPDOWN_PLACEHOLDER}
      width={width}
      label={label}
    >
      {nodeList.map((node) => (
        <SelectOption
          data-testid="nodeIdSelectOption"
          key={node}
          value={node}
          label={node}
        />
      ))}
    </Select>
  );
}
