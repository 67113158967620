import AppLayout from "@amzn/meridian/app-layout";
import AdminMasthead from "../adminMasthead/AdminMasthead";
import AdminSideMenu from "../adminSideMenu/AdminSideMenu";
import EUAdminSideMenu from "../adminSideMenu/EUAdminSideMenu";
import Footer from "../footer/Footer";
import AdminContext from "../../store/admin-context";
import { useContext } from "react";
import AMZLAdminSideMenu from "../adminSideMenu/AMZLAdminSideMenu";
import {AMZL_NODES} from "../../constants";

export default function AdminLayout({ children }) {
  const adminContext = useContext(AdminContext);
  const { isEUDomain, selectedNode } = adminContext;
  console.log("adminlayout isEUDomain:"+isEUDomain);

  //Upload Roster feature available for EU domain sites and sites starting with AMZL

  let menuComponent;
  if (isEUDomain || selectedNode?.startsWith("AMZL")) {
    menuComponent = EUAdminSideMenu;
  } else if (selectedNode !== undefined && AMZL_NODES.has(selectedNode.toUpperCase())) {
    menuComponent = AMZLAdminSideMenu;
  } else {
    menuComponent = AdminSideMenu;
  }

  return (
    <AppLayout
      headerComponent={AdminMasthead}
      sidebarComponent={menuComponent}
      footerComponent={Footer}
      backgroundColor="primary"
    >
      <AdminMasthead />
      {((isEUDomain || selectedNode?.startsWith("AMZL")) && <EUAdminSideMenu />)
          || (selectedNode !== undefined && AMZL_NODES.has(selectedNode.toUpperCase()) && <AMZLAdminSideMenu />)
          || <AdminSideMenu />}
      {children}
      <Footer />
    </AppLayout>
  );
}
