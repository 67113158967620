import React from "react";
import Icon from "@amzn/meridian/icon";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";
import alertTokens from "@amzn/meridian-tokens/base/icon/alert";
import bulbTokens from "@amzn/meridian-tokens/base/icon/bulb";
import checkLargeTokens from "@amzn/meridian-tokens/base/icon/check-large";
import closeMediumTokens from "@amzn/meridian-tokens/base/icon/close-medium";
import { colorRed400, colorGreen500, colorOrange400 } from "@amzn/meridian-tokens/base/color";
import { css } from "emotion";
import "./AuditComponents.scss";

const greenText = css({
  color: colorGreen500
});

export default function GreenCheckMarkWithTooltip({ tooltipText = "#Badged In" }) {
  return <Tooltip position="top" title={tooltipText}>
    <span className="checkMarkIcon" >
      <Icon tokens={checkLargeTokens} />
    </span>
  </Tooltip>
}


