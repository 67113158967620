import React from "react";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import alertKnockoutToken from "@amzn/meridian-tokens/base/icon/alert-knockout";
import thumbsUpToken from "@amzn/meridian-tokens/base/icon/thumbs-up";
import {
  UPLOAD_ROSTER_SUBMIT_SUCCESS_STATUS,
  UPLOAD_ROSTER_SUBMIT_ERROR_MESSAGE,
  UPLOAD_ROSTER_PROCESSING_MESSAGE, ALERT_AUTHENTICATION_MESSAGE,
} from "../../constants";

export default function UploadRosterSubmitModal({
  open,
  responseMessage,
  onClose,
}) {
  let shortText = "";

  switch (responseMessage) {
    case UPLOAD_ROSTER_SUBMIT_SUCCESS_STATUS:
      shortText = "Success!";
      break;
    case UPLOAD_ROSTER_PROCESSING_MESSAGE:
      shortText = UPLOAD_ROSTER_PROCESSING_MESSAGE;
      break;
    case ALERT_AUTHENTICATION_MESSAGE:
      shortText = ALERT_AUTHENTICATION_MESSAGE;
      break;
    case UPLOAD_ROSTER_SUBMIT_ERROR_MESSAGE:
      shortText = UPLOAD_ROSTER_SUBMIT_ERROR_MESSAGE;
      break;
    default:
      shortText = responseMessage;
      break;
  }

  return (
    <React.Fragment>
      <Modal
        open={open}
        title=""
        onClose={onClose}
        scrollContainer="viewport"
        width={300}
        closeLabel="Close"
        data-type="uploadModal"
      >
        <Text id="modal-description">
          {responseMessage === UPLOAD_ROSTER_SUBMIT_SUCCESS_STATUS ? (
            <Icon tokens={thumbsUpToken} />
          ) : (
            <Icon tokens={alertKnockoutToken} />
          )}{" "}
          <span data-type="uploadModalShortText">{shortText}</span>
        </Text>
        <ModalFooter>
          <Row alignmentHorizontal="right" widths="fit">
            <Button type="primary" size="small" onClick={onClose} data-type="uploadModalConfirm">
              Confirm
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
