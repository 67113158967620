// Promise polyfill
import "core-js/features/promise";

import { render } from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "./components/App";
import { getMidwayJwtToken } from "./auth/MidwayJwtToken";
import axios from "axios";
import { initializeAppSettings } from "./config/AppSettings";
import "@amzn/meridian-tokens/base/font/amazon-ember.css";
import "./index.scss";

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
  const jwtToken = await getMidwayJwtToken();
  config.headers.Authorization = `Bearer ${jwtToken}`;
  return config;
});

(async () => {
  // Make sure Midway is present before rendering
  await getMidwayJwtToken();

  // Initialize application settings
  const appSettings = (await axios("/settings.json")).data;
  initializeAppSettings(appSettings);

  render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.querySelector("#app")
  );
})();
