import React from "react";
import Icon from "@amzn/meridian/icon";
import Tooltip from "@amzn/meridian/tooltip";
import alertTokens from "@amzn/meridian-tokens/base/icon/alert";
import "./AuditComponents.scss";

export default function RedAlertWithTooltip({ tooltipText = "#Not Badged In" }) {
  return <Tooltip position="top" title={tooltipText}>
    <span className="alertIcon" >
      <Icon tokens={alertTokens} />
    </span>
  </Tooltip>
}