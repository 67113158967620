import React, { useContext, useEffect, useState } from "react";
import Select, { SelectOption } from "@amzn/meridian/select";
import Column from "@amzn/meridian/column";
import AdminContext from "../../store/admin-context";
import AuditContext from "../../store/audit-store";
import AdminApiFactory from "../../admin-api/AdminApiFactory";
import { AuditDetails } from "../../admin-api/generated-src";
import Row from "@amzn/meridian/row";

export default function RosterSelect() {
  const auditContext = useContext(AuditContext);
  const adminContext = useContext(AdminContext);
  const { selectedNode } = adminContext;
  const { currentRosterId,
    setCurrentRosterId,
    rosterIdList,
    setAuditRecordList,
    setAuditStats,
    setIsAuditTableLoading } = auditContext;
  const AdminApi = AdminApiFactory();

  const [newDate, setNewDate] = useState(currentRosterId?.split("/")[0]);
  const [sortType, setSortType] = useState(currentRosterId?.split("/")[1]);
  
  const removeDuplicates = (arr: Array<any>) => {
    return arr.filter((item, 
        index) => arr.indexOf(item) === index);
  }

  const dateArray: Array<any> = rosterIdList.map(({ rosterId = ""}) => rosterId.split("/")[0]);
  const filteredDateArray: Array<any> = removeDuplicates(dateArray);
  const sortTypeRosterIds:Array<any> = rosterIdList.filter((n) => n.rosterId?.startsWith(newDate!));
  let sortTypeArray:Array<any> = sortTypeRosterIds.map(({ rosterId = "" }) => rosterId?.split("/")[1]);

 useEffect(() => {
    let newRosterID: any = newDate+"/"+sortType;
    setCurrentRosterId(newRosterID);
    //Checking if the newRosterID exists in the rosterIdList
    const found = rosterIdList.some(n => n.rosterId === newRosterID);
    found && (async () => {
      console.log("Rosterid :"+newRosterID);
      setIsAuditTableLoading(true);
      try {
        const auditDetailsResponse: AuditDetails = (await AdminApi.getAuditDetailsForCurrentShift(selectedNode, newRosterID)).data as AuditDetails;
        if (auditDetailsResponse) {
          const { AuditRecord = [],
            AuditStats = {} } = auditDetailsResponse;
          setAuditStats(AuditStats);
          setAuditRecordList(AuditRecord);
        } else {
          console.error(`Got empty response from /audit/${selectedNode}`)
        }
      } catch (ex) {
        console.error(`Error fetching /audit/${selectedNode}`, ex);
      } finally {
        setIsAuditTableLoading(false);
      }
    })();
  }, [newDate, sortType])

  const changeDateHandler = (selectedDate) => {
    setNewDate(selectedDate);
    setSortType("");
  }

  return (
    <Row>
      <Select
        value={newDate}
        onChange={changeDateHandler}
        placeholder="Select Date"
        width={180}
      >
        {filteredDateArray.map((dateValue) => 
          <SelectOption 
            key={`${dateValue}-date`} 
            value={dateValue} 
            label={dateValue}
          /> )}
      </Select>
      <Select
        value={sortType}
        onChange={setSortType}
        placeholder="Select SortType"
        width={180}
      >
        {sortTypeArray.map((sortValue) => 
          <SelectOption 
            key={`${sortValue}-sortType`} 
            value={sortValue} 
            label={sortValue} 
          /> )}
      </Select>
    </Row>
  )
}