import React from "react";
import Tooltip from "@amzn/meridian/tooltip";
import "./AuditComponents.scss";

export default function TrainedRolesCell({ trainedRolesStr }) {
  return <Tooltip position="right" title={trainedRolesStr}>
    <div className="trainedRolesCell">
      {trainedRolesStr}
    </div>
  </Tooltip>
}