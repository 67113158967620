import Input from "@amzn/meridian/input";
import Text from "@amzn/meridian/text";
import {useHistory} from "react-router-dom";
import {useState} from "react";
import Loader from "@amzn/meridian/loader";
import Column from "@amzn/meridian/column";
import i18n from "../../i18n";
import AdminApiFactory from "../../admin-api/AdminApiFactory";

export default function ScanForm(props) {
    const [value, setValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const AdminApi = AdminApiFactory();
    const cleanUp = () => {
        setValue("");
    };

    const blurHandler = (blurEvent) => {
        // force focusing on the input field to scan badge
        blurEvent.target.focus();
    };
    const submitHandler = (event) => {
        event.preventDefault();
        //process only non-empty badgeId's
        if(value) {
            setIsLoading(true);
            let rightNow = new Date();
            AdminApi.startRosterAssignmentLaborTracking(props.nodeId, value, {
                nodeId: props.nodeId,
                badgeId: value,
                scanTimestamp: new Date(rightNow.getTime() - (rightNow.getTimezoneOffset() * 60000)).toISOString(),
                scanTimestampEpoch: Math.floor(rightNow.getTime() / 1000).toString(),
            }).then((badgeScanResponse) => {
                setIsLoading(false);
                history.replace({
                    pathname: `/${props.nodeId}/kiosk-labor-track/${value}/status`,
                    state: badgeScanResponse,
                });
            }).catch((ex) => {
                setIsLoading(false);
                history.replace({
                    pathname: `/${props.nodeId}/kiosk-labor-track/${value}/status`,
                    state: {
                        status: "error",
                        data: ex,
                    },
                });
            });
        }
        cleanUp();
    };

    return isLoading ? (
        <Loader />
    ) : (
        <form onSubmit={submitHandler}>
            <Column spacing="200">
                <Text
                    data-testid="label"
                    tag="label"
                    alignment="center"
                    type="d100"
                    color="primary"
                    htmlFor="badgeId"
                >
                    {i18n.t('title')}
                </Text>
                <Input
                    data-testid="badgeId"
                    id="badgeId"
                    name="badgeId"
                    value={value}
                    //badgeId's can only be number
                    type="number"
                    onChange={setValue}
                    onBlur={blurHandler}
                    placeholder="123..."
                    inaccessibleAutoFocus
                />
            </Column>
        </form>
    );
}