import React from "react";
import Alert from "@amzn/meridian/alert";
import { AlertType } from "@amzn/meridian/alert/alert";
import Toaster from "@amzn/meridian/toaster";
import {ALERT_AUTHENTICATION_MESSAGE, CALM_CODE_SUBMIT_SUCCESS_STATUS} from "../../../constants";

export default function AddRolesMessageToast({
  message,
  toasts,
  onCloseToast,
}) {
  let shortMessage = "";
  let type: AlertType = "success";
  switch (message) {
    case CALM_CODE_SUBMIT_SUCCESS_STATUS:
      shortMessage = "Success!";
      type = "success";
      break;
    case ALERT_AUTHENTICATION_MESSAGE:
      shortMessage = ALERT_AUTHENTICATION_MESSAGE;
      type = "error";
      break;
    default:
      shortMessage = message;
      type = "error";
      break;
  }
  return (
    <Toaster toasts={toasts} onCloseToast={onCloseToast}>
      {() => (
        <Alert
          toast={true}
          type={type}>
          {message}
        </Alert>
      )}
    </Toaster>
  );
}
