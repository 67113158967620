import React, { useContext, useEffect, useState } from "react";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Icon from "@amzn/meridian/icon";
import Text from "@amzn/meridian/text";
import refreshTokens from "@amzn/meridian-tokens/base/icon/refresh";
import {
  DEFAULT_COUNTDOWN_IN_SECONDS,
  AUDIT_TABLE_OBJECT_LIST,
} from "../../constants";
import AuditContext from "../../store/audit-store";

export default function AuditActionGroup() {
  const auditContext = useContext(AuditContext);
  const { auditRecordList, currentRosterId, setFlipRefresh } = auditContext;
  const [count, setCount] = useState(DEFAULT_COUNTDOWN_IN_SECONDS);

  useEffect(() => {
    let internal = 0;
    internal = window.setInterval(() => {
      setCount((prevSecond) => {
        if (prevSecond <= 1) {
          clearInterval(internal);
          setFlipRefresh();
          return DEFAULT_COUNTDOWN_IN_SECONDS;
        }
        return prevSecond - 1;
      });
    }, 1000);

    return () => clearInterval(internal);
  }, []);

  const getMinuteTimerStrFromSeconds = (seconds) => {
    return new Date(seconds * 1000).toISOString().substring(14, 19);
  }

  const exportToCSVHandler = () => {
    const csvString = [
      AUDIT_TABLE_OBJECT_LIST.map(item => item.title),
      ...auditRecordList.map(item => {
        let row: any = [];
        AUDIT_TABLE_OBJECT_LIST.forEach(auditObj => {
          if (Array.isArray(item[auditObj.fieldName])) {
            row.push(JSON.stringify(item[auditObj.fieldName].join(",")));
          } else {
            row.push(item[auditObj.fieldName]);
          }
        });
        return row;
      })
    ]
      .map(e => e.join(","))
      .join("\n");
    let csvContent = "data:text/csv;charset=utf-8," + csvString;
    let encodedUri = encodeURI(csvContent);
    const fixedEncodedURI = encodedUri.replaceAll('#', '%23');
    let link = document.createElement("a");
    link.setAttribute("href", fixedEncodedURI);
    link.setAttribute("download", `${currentRosterId}.csv` || "roster-shift.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const refreshClickHandler = () => {
    setFlipRefresh();
    setCount(DEFAULT_COUNTDOWN_IN_SECONDS);
  }
  return <Column>
    <Row spacing="medium medium">
      <Button type="icon" onClick={refreshClickHandler}>
        <Icon tokens={refreshTokens}>Refresh</Icon>
      </Button>
      <Text>Refresh in {getMinuteTimerStrFromSeconds(count)}</Text>
      <Button type="secondary" onClick={exportToCSVHandler}>Export To CSV</Button>
    </Row>
  </Column>
}