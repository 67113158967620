import React, { useState, createContext } from "react";
import { EUROPE_DOMAINNAME, NODE_LIST_KEY, USER_NAME_KEY } from "../constants";

interface adminStateProps {
  username: string;
  selectedNode: string;
  nodeList: string[];
  isSideBarOpen: boolean;
  isEUDomain: boolean;
  changeSelectedNode(nodeId: string): any;
  toggleSideBar(): any;
  loadNodeList(nodeList: string[]): any;
  changeUsername(username: string): any;
}

export const initialState: adminStateProps = {
  username: "",
  selectedNode: "",
  nodeList: [],
  isSideBarOpen: true,
  isEUDomain: false,
  changeSelectedNode: () => { },
  toggleSideBar: () => { },
  loadNodeList: () => { },
  changeUsername: () => { },
};

const AdminContext = createContext(initialState);

export function AdminContextProvider({ children }) {
  const [sessionUsername, setSessionUsername] = useState(localStorage.getItem(USER_NAME_KEY) || initialState.username);
  const [userSelectedNode, setUserSelectedNode] = useState(
    initialState.selectedNode
  );
  const [userIsSideBarOpen, setUserIsSideBarOpen] = useState(
    initialState.isSideBarOpen
  );
  const localStorageNodeList = localStorage.getItem(NODE_LIST_KEY);
  const [userNodeList, setUserNodeList] = useState(localStorageNodeList ? JSON.parse(localStorageNodeList) : initialState.nodeList);

  function changeSelectedNode(nodeId) {
    setUserSelectedNode(nodeId);
  }
  function loadNodeList(nodeList) {
    setUserNodeList(nodeList);
  }

  function toggleSideBar() {
    setUserIsSideBarOpen((prevIsBarOpen) => !prevIsBarOpen);
  }

  function changeUsername(username) {
    setSessionUsername(username);
  }

  const context: adminStateProps = {
    username: sessionUsername,
    selectedNode: userSelectedNode,
    nodeList: userNodeList,
    isSideBarOpen: userIsSideBarOpen,
    isEUDomain: (window.location.hostname).includes(EUROPE_DOMAINNAME),
    changeSelectedNode,
    loadNodeList,
    toggleSideBar,
    changeUsername,
  };

  return (
    <AdminContext.Provider value={context}>{children}</AdminContext.Provider>
  );
}

export default AdminContext;
