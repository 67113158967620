import React, { createContext, useState } from "react";
import { AuditStats, AuditRecord, NodeRosterIdMappingInfo } from "../admin-api/generated-src";

interface auditStateProps {
  currentRosterId: string | undefined;
  auditRecordList: AuditRecord[];
  auditStats: AuditStats;
  rosterIdList: NodeRosterIdMappingInfo[],
  isAuditTableLoading: boolean,
  flipRefresh: boolean;
  setCurrentRosterId(rosterId: string | undefined): any;
  setAuditRecordList(auditRecordList: AuditRecord[]): any;
  setAuditStats(auditStats: AuditStats): any;
  setRosterIdList(rosterIdList: NodeRosterIdMappingInfo[]): any;
  setIsAuditTableLoading(isAuditTableLoading: boolean): any;
  setFlipRefresh(): any;
}

export const initialState: auditStateProps = {
  currentRosterId: undefined,
  auditRecordList: [],
  auditStats: {},
  rosterIdList: [],
  isAuditTableLoading: false,
  flipRefresh: false,
  setCurrentRosterId: () => { },
  setAuditRecordList: () => { },
  setAuditStats: () => { },
  setRosterIdList: () => { },
  setIsAuditTableLoading: () => { },
  setFlipRefresh: () => { }
};

const AuditContext = createContext(initialState);

export function AuditContextProvider({ children }) {
  const [userCurrentRosterId, setUserCurrentRosterId] = useState(initialState.currentRosterId);
  const [userAuditRecordList, setUserAuditRecordList] = useState(initialState.auditRecordList);
  const [userAuditStats, setUserAuditStats] = useState(initialState.auditStats);
  const [userRosterIdList, setUserRosterIdList] = useState(initialState.rosterIdList);
  const [userIsAuditTableLoading, setUserIsAuditTableLoading] = useState(initialState.isAuditTableLoading);
  const [userFlipRefresh, setUserFlipRefresh] = useState(initialState.flipRefresh);

  function setCurrentRosterId(rosterId) {
    setUserCurrentRosterId(rosterId);
  }

  function setAuditRecordList(auditRecordList) {
    setUserAuditRecordList(auditRecordList);
  }

  function setAuditStats(auditStats) {
    setUserAuditStats(auditStats);
  }

  function setRosterIdList(rosterIdList) {
    setUserRosterIdList(rosterIdList);
  }

  function setIsAuditTableLoading(isAuditTableLoading) {
    setUserIsAuditTableLoading(isAuditTableLoading);
  }

  function setFlipRefresh() {
    setUserFlipRefresh((prevUserRefresh) => !prevUserRefresh);
  }

  const context: auditStateProps = {
    currentRosterId: userCurrentRosterId,
    auditRecordList: userAuditRecordList,
    auditStats: userAuditStats,
    rosterIdList: userRosterIdList,
    isAuditTableLoading: userIsAuditTableLoading,
    flipRefresh: userFlipRefresh,
    setCurrentRosterId,
    setAuditRecordList,
    setAuditStats,
    setRosterIdList,
    setIsAuditTableLoading,
    setFlipRefresh
  };

  return (
    <AuditContext.Provider value={context}>{children}</AuditContext.Provider>
  );
}

export default AuditContext;
