import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SideMenu, { SideMenuLink } from "@amzn/meridian/side-menu";
import homeIconTokens from "@amzn/meridian-tokens/base/icon/home";
import cogIconTokens from "@amzn/meridian-tokens/base/icon/cog";
import clockIconTokens from "@amzn/meridian-tokens/base/icon/clock";
import {
  HOME_LINK_LABEL,
  CONFIGURATION_LINK_LABEL,
  CONFIGURATION_CALM_CODE_LABEL,
  CONFIGURATION_UPLOAD_ROSTER_LABEL,
  SIDE_MENU_HASH_KEY,
  SIDE_MENU_OPEN_HASH_KEY,
  AUDIT_LINK_LABEL,
} from "../../constants";
import AdminContext from "../../store/admin-context";

const NAVLINK_HASH = {
  home: "#home",
  configuration: "#configuration",
  calmCode: "#calmCode",
  uploadRoster: "#uploadRoster",
  audit: "#audit",
};

const NAVLINK_HASH_OBJECTS = [
  {
    hashName: NAVLINK_HASH.home,
    label: HOME_LINK_LABEL,
    iconTokens: homeIconTokens,
    isNested: false,
    hrefLink: "/",
  },
  {
    hashName: NAVLINK_HASH.configuration,
    label: CONFIGURATION_LINK_LABEL,
    iconTokens: cogIconTokens,
    isNested: true,
    hrefLink: "/config",
    nestedLinks: [
      {
        hashName: NAVLINK_HASH.calmCode,
        label: CONFIGURATION_CALM_CODE_LABEL,
        hrefLink: "/calm-code",
      },
      {
        hashName: NAVLINK_HASH.uploadRoster,
        label: CONFIGURATION_UPLOAD_ROSTER_LABEL,
        hrefLink: "/upload-roster",
      }
    ]
  },
  {
    hashName: NAVLINK_HASH.audit,
    label: AUDIT_LINK_LABEL,
    iconTokens: clockIconTokens,
    isNested: false,
    hrefLink: "/audit"
  }
];

const PATH_NAMES_HASH_ARRAY = [{
  hrefLink: "/audit",
  hashName: NAVLINK_HASH.audit,
},
  {
  hrefLink: "/calm-code",
  hashName: NAVLINK_HASH.calmCode,
},
{
  hrefLink: "/upload-roster",
  hashName: NAVLINK_HASH.uploadRoster,
},
];

export default function EUAdminSideMenu() {
  const location = useLocation();
  const history = useHistory();
  const [hash, setHash] = useState(getDefaultHash(location));
  const [openLinkHash, setOpenLinkHash] = useState(localStorage.getItem(SIDE_MENU_OPEN_HASH_KEY) || null);

  function getDefaultHash(location) {
    let localStoredHash = localStorage.getItem(SIDE_MENU_HASH_KEY);
    let locationHash = getLocationHashFromPath(location.pathname);
    if (localStoredHash === locationHash) {
      return localStoredHash;
    } else {
      localStorage.setItem(SIDE_MENU_HASH_KEY, locationHash);
      return locationHash;
    }
  }

  function getLocationHashFromPath(pathname) {
    for (let linkItem of PATH_NAMES_HASH_ARRAY) {
      if (pathname.includes(linkItem.hrefLink)) {
        return linkItem.hashName;
      }
    }
    return NAVLINK_HASH.home;
  }


  const adminContext = useContext(AdminContext);
  const { selectedNode, isSideBarOpen } = adminContext;
  
  return (
      <SideMenu open={isSideBarOpen}>
        {NAVLINK_HASH_OBJECTS.map(({isNested, hashName, label, iconTokens, hrefLink, nestedLinks = []}) => {
          if (isNested) {
            return <SideMenuLink
                key={hashName}
                href={hashName}
                selected={hash === hashName}
                open={openLinkHash === hashName}
                onClick={(hashValue) => {
                  setHash(hashValue);
                  localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                  let nextOpenLinkHash = openLinkHash === hashName ? null : hashValue;
                  setOpenLinkHash(nextOpenLinkHash);
                  localStorage.setItem(SIDE_MENU_OPEN_HASH_KEY, nextOpenLinkHash);
                }}
                disabled={!selectedNode}
                icon={iconTokens}
            >
              {label}
              {nestedLinks.map((nestedLink) => (
                  <SideMenuLink
                      key={nestedLink.hashName}
                      href={nestedLink.hashName}
                      selected={hash === nestedLink.hashName}
                      disabled={!selectedNode}
                      onClick={(hashValue) => {
                        setHash(hashValue);
                        localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                        history.push(`/${selectedNode}${hrefLink}${nestedLink.hrefLink}`);
                      }}
                  >
                    {nestedLink.label}
                  </SideMenuLink>
              ))}
            </SideMenuLink>
          } else {
            return <SideMenuLink
                key={hashName}
                href={hashName}
                selected={hash === hashName}
                onClick={(hashValue) => {
                  setHash(hashValue);
                  localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                  history.push(selectedNode ? `/${selectedNode}${hrefLink}` : "/");
                }}
                disabled={hashName === NAVLINK_HASH.home ? false : !selectedNode}
                icon={iconTokens}
            >{label}</SideMenuLink>
          }
        })}
      </SideMenu>
  );
}
