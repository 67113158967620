import { TableCell, TableRow } from "@amzn/meridian/table";
import {useCallback, useState} from "react";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import React from "react";
import {LMSLaborTrackingRole} from "../../../admin-api/generated-src";
import DeleteRowModal from "../../modal/DeleteRowModal";



function LMSCertificateRow({
                         certificate,
                         handleSaveClick,
                         handleDeleteClick,
                     }) {

    const [calmCode, setCalmCode] = useState(certificate.calmCode);
    const [delayInMinutes, setdelayInMinutes] = useState(certificate.delayInMinutes);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    /**
     * Function to handle save button
     */
    const saveHandler = () => {
        const updatedRole: LMSLaborTrackingRole = {
            nodeId: certificate.nodeId,
            certificateName: certificate.certificateName,
            certificateId: certificate.certificateId,
            calmCode: calmCode.trim(),
            isLMS: certificate.isLMS,
            delayInMinutes: delayInMinutes,
        };
        handleSaveClick(updatedRole);
    };

    const getUniqueKey = (certificateName, fieldName) => {
        return `${certificateName}-${fieldName}`;
    };

    const deleteHandler = () => {
        setOpenDeleteModal(true)
    }
    const onConfirmDelete = useCallback(() => setOpenDeleteModal(false), []);

    return (
        <>
            <DeleteRowModal
                open={openDeleteModal}
                onClose={onConfirmDelete}
                confirmHandler={handleDeleteClick}
                role={certificate}
            />
            <TableRow key={getUniqueKey(certificate.certificateName, "row")}>
                <TableCell key={getUniqueKey(certificate.certificateName, `certificateName-${certificate.certificateName}`)}>
                    {certificate.certificateName}
                </TableCell>
                <TableCell key={getUniqueKey(certificate.certificateName, "calmCode")}>
                    <Input
                        type="text"
                        value={calmCode}
                        onChange={setCalmCode}
                        data-type={certificate.certificateName + "calmCode"}
                    />
                </TableCell>
                <TableCell key={getUniqueKey(certificate.certificateName, "delayInMinutes")}>
                    <Input
                        type="number"
                        value={delayInMinutes}
                        onChange={setdelayInMinutes}
                        data-type={certificate.certificateName + "delayInMinutes"}
                    />
                </TableCell>
                <TableCell >
                    <Button onClick={saveHandler} data-type={certificate.certificateName + "update"}>Update</Button>
                </TableCell>
                <TableCell  >
                    <Button onClick={deleteHandler}
                            disabled={certificate.isLMS}
                            data-type={certificate.certificateName + "delete"}>Delete</Button>
                </TableCell>
            </TableRow>
        </>
    );
}

export default LMSCertificateRow;