import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import ScanResult from "./ScanResult";
import Title from "../Title";
import Banner from "../Banner";
import BarcodeReader from "react-barcode-reader";
import { TIMEOUT_OFFSET } from "../../../constants";
import Center from "../Center";
import AdminApiFactory from "../../../admin-api/AdminApiFactory";

export default function StatusPage(props) {
  const { nodeId, badgeId } = useParams<{ nodeId: string, badgeId: string }>();
  const { status } = (props.location && props.location.state && props.location.state.data.response) || {};
  const { message, rosterShiftInfo, badgeScanAssignmentInfo } =
  (props.location && props.location.state && props.location.state.data) || {};
  const history = useHistory();
  const AdminApi = AdminApiFactory();

  const handleError = (error) => {
    console.log(error);
  };

  const handleScan = (scannedData) => {
    let rightNow = new Date();
    AdminApi.startRosterAssignmentLaborTracking(nodeId, scannedData, {
      nodeId: nodeId,
      badgeId: scannedData,
      scanTimestamp: new Date(rightNow.getTime() - (rightNow.getTimezoneOffset() * 60000)).toISOString(),
      scanTimestampEpoch: Math.floor(rightNow.getTime() / 1000).toString(),
    }).then((badgeScanResponse) => {
      history.replace({
        pathname: `/${nodeId}/kiosk-labor-track/${scannedData}/status`,
        state: badgeScanResponse,
      });
    }).catch((ex) => {
      history.replace({
        pathname: `/${nodeId}/kiosk-labor-track/${scannedData}/status`,
        state: {
          status: "error",
          data: ex,
        },
      });
    });
  };

  useEffect(() => {
    // clean up existing timer before setting up a new one
    let timer = setTimeout(() => {
      clearTimeout(timer);
      history.replace(`/${nodeId}/kiosk-labor-track`);
    }, TIMEOUT_OFFSET);

    return () => {
      clearTimeout(timer);
      props.location.state = {};
    };
  }, [badgeId]);

  return (
    <Center>
      <Title nodeId={nodeId} />
      <Banner>
        <ScanResult
          message={message}
          status={status}
          rosterShiftInfo={rosterShiftInfo}
          badgeScanAssignmentInfo={badgeScanAssignmentInfo}
        />
      </Banner>
      <BarcodeReader onError={handleError} onScan={handleScan} />
    </Center>
  );
};
