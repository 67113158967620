import React,{useState} from "react";
import Select, { SelectOption } from "@amzn/meridian/select";
import escapeRegExp from "lodash/escapeRegExp"
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";

export default function SearchableSelect({ userSelectValue, changeValueHandler, fieldName, title, options }) {

    const [searchQuery, setSearchQuery] = useState();
    
  // Here we filter out options by creating an escaped regular expression
  // out of the search query and test it against each option label
  const searchRegExp = new RegExp(escapeRegExp(searchQuery), "i")
  const matchedOptions = options.filter(
    option => !searchQuery || searchRegExp.test(option)
  )

  return (
    <Select
      value={userSelectValue}
      onChange={changeValueHandler(fieldName)}
      placeholder={title}
      searchQuery={searchQuery}
      onSearch={setSearchQuery}
    >
      {matchedOptions.map((optionValue) => 
        optionValue && <SelectOption key={optionValue} value={optionValue} label={optionValue} /> 
      )}
      {!matchedOptions.length ? (
        // If there are no matched options, we display a message to our
        // users saying their query returned no results
        <Column alignmentVertical="center" spacing="300" spacingInset="600">
          <Text alignment="center">No results</Text>
        </Column>
      ) : null}
    </Select>
  )
}