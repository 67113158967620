import React, { useContext, useState } from "react";
import Masthead, {
  MastheadTitle,
  MastheadMenuButton,
} from "@amzn/meridian/masthead";
import NodeIdSelect from "../nodeIdSelect/NodeIdSelect";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import { colorGray300 } from "@amzn/meridian-tokens/base/color";
import helpIconTokens from "@amzn/meridian-tokens/base/icon/help";
import AdminContext from "../../store/admin-context";
import logo from "../../assets/faststart_filled_100.png";
import feedback_icon from "../../assets/feedback_icon_s.png";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import NeedAssistanceModal from "../modal/NeedAssistanceModal";
import { FEEDBACK_LINK } from "../../constants";
import Link from "@amzn/meridian/link";

export default function AdminMasthead() {
  const adminContext = useContext(AdminContext);

  const { toggleSideBar } = adminContext;
  
  const [showNeedAssistanceModal, setShowNeedAssistanceModal] = useState(false);
  
  const onClickHelp = () => {
    setShowNeedAssistanceModal(true);
  }

  const onCloseHelp = () => {
    setShowNeedAssistanceModal(false);
  }

  return (
    <Masthead backgroundColor={colorGray300}>
      <MastheadMenuButton
        data-testid="adminMastheadButton"
        onClick={toggleSideBar}
        open={true}
      />
      <MastheadTitle href="#">
        <Row>
          <img
            style={{ width: "40px", height: "40px" }}
            src={logo}
            alt="logo"
          ></img>
          <Text>FastStart</Text>
        </Row>
      </MastheadTitle>
      <NodeIdSelect width={200} />
      <NeedAssistanceModal 
        open={showNeedAssistanceModal}
        onClose={onCloseHelp}
      />
      <Button type="link" onClick={onClickHelp}>
        <span style={{ color: "#232F3E"}}>
          <Icon tokens={helpIconTokens}>Help</Icon>
        </span>
      </Button>
      <Link
         href={FEEDBACK_LINK}
         target="_blank"
         rel="noopener noreferrer"
       >
        <img 
          src={feedback_icon}
          alt="feedback_logo"
          style={{ verticalAlign: "bottom"}}
        ></img>
       </Link>
    </Masthead>
  );
}
